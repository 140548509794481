import * as actionTypes from './actionTypes';

const initialState = {
  isVerified: true,
  isUnverified: true,
  isDeleted: false,
};

export default function contactsTableFilters(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CONDOR_ACCOUNTS_TABLE_FILTER_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.RESET_CONDOR_ACCOUNTS_TABLE_FILTERS:
      return initialState;
    default:
      return state;
  }
}
