import get from 'lodash/get';

interface IConfig {
  pathToLabel?: string,
  pathToValue?: string,
  labelRenderer?: (item: any, i: number) => any,
}

export const getOptionMapper = (config: IConfig = {}) => (item: any, i?: number) => {
  const { pathToLabel, pathToValue, labelRenderer } = config;

  return {
    value: get(item, pathToValue || 'id'),
    label: labelRenderer ? labelRenderer(item, i) : get(item, pathToLabel || 'name'),
  };
};

/**
 * @param {Object} item
 * @param {Number} [index]
 * @returns {{label: String, value: String|Number}}
 */
export const mapToDefaultOption = getOptionMapper();

export const mapOptionToValue = (selectedOption) => {
  if ([null, undefined].indexOf(selectedOption) > -1) {
    return null;
  }

  switch (typeof selectedOption) {
    case 'object':
      return selectedOption.value || null;
    case 'string':
      return selectedOption || null;
    case 'number':
    default:
      return selectedOption;
  }
};
