export const OPEN_CONDOR_ACCOUNT_MAPPING_MODAL = 'OPEN_CONDOR_ACCOUNT_MAPPING_MODAL';
export const CLOSE_CONDOR_ACCOUNT_MAPPING_MODAL = 'CLOSE_CONDOR_ACCOUNT_MAPPING_MODAL';

export const OPEN_CONDOR_ACCOUNT_MAPPING_PROGRESS_MODAL = 'OPEN_CONDOR_ACCOUNT_MAPPING_PROGRESS_MODAL';
export const CLOSE_CONDOR_ACCOUNT_MAPPING_PROGRESS_MODAL = 'CLOSE_CONDOR_ACCOUNT_MAPPING_PROGRESS_MODAL';

export const GET_CONDOR_FILTERED_ACCOUNTS_OPTIONS = 'GET_CONDOR_FILTERED_ACCOUNTS_OPTIONS';
export const GET_CONDOR_FILTERED_ACCOUNTS_OPTIONS_SUCCESS = 'GET_CONDOR_FILTERED_ACCOUNTS_OPTIONS_SUCCESS';
export const GET_CONDOR_FILTERED_ACCOUNTS_OPTIONS_ERROR = 'GET_CONDOR_FILTERED_ACCOUNTS_OPTIONS_ERROR';

export const SUBSCRIBE_TO_SOCKET_REMAPPING = 'SUBSCRIBE_TO_SOCKET_REMAPPING';
export const SUBSCRIBE_TO_SOCKET_REMAPPING_SUCCESS = 'SUBSCRIBE_TO_SOCKET_REMAPPING_SUCCESS';
export const SUBSCRIBE_TO_SOCKET_REMAPPING_ERROR = 'SUBSCRIBE_TO_SOCKET_REMAPPING_ERROR';

export const START_REMAPPING_PROCESS = 'START_REMAPPING_PROCESS';
export const START_REMAPPING_PROCESS_SUCCESS = 'START_REMAPPING_PROCESS_SUCCESS';
export const START_REMAPPING_PROCESS_ERROR = 'START_REMAPPING_PROCESS_ERROR';

export const REMAP_PROCESS_UPDATE = 'REMAP_PROCESS_UPDATE';
export const REMAP_PROCESS_FINISHED = 'REMAP_PROCESS_FINISHED';

export const RESET_MAPPINGS_METADATA = 'RESET_MAPPINGS_METADATA';
