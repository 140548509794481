import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getDatabasesStructure,
  resetDatabasesStructure,
  changePath,
  changePathData,
} from '@/condor/application/actions/databases/structure/actions';
import DataSourceStructureModal from '../components/DataSourceStructureModal';

function mapStateToProps(state) {
  return {
    isFetching: state.technicalSupport.databases.structure.isFetching,
    structure: state.technicalSupport.databases.structure.databases,
    path: state.technicalSupport.databases.structure.path,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getList: getDatabasesStructure,
    resetState: resetDatabasesStructure,
    changePath,
    changePathData,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataSourceStructureModal);
