import { combineReducers } from 'redux';
import roadshow from './roadshow';
import tabs from './tabs';
import industry from '@/dealroadshow/application/actions/industry/data/reducer';
import absSector from '@/dealroadshow/application/actions/absSector/data/reducer';
import cdnList from '@/condor/application/actions/cdn/reducer';

import dealSettings from '../modules/DealSettings/reducers/dealSettings';
import roadshowMapping from '../modules/DealSettings/reducers/roadshowMapping';
import dealFiles from '../modules/DealFiles/reducers';
import dealSlides from '../modules/DealSlides/reducers';
import audioVideo from '../modules/AudioVideo/reducers';
import analytics from '../modules/Analytics/reducers';
import customModal from '../modules/CustomModal/reducers';
import loginLimits from '../modules/LoginLimits/reducers';
import auditTrail from '../auditTrail/reducer';

export default combineReducers({
  tabs,
  roadshow,
  dealSettings,
  roadshowMapping,
  industry,
  absSector,
  cdnList,
  dealFiles,
  dealSlides,
  audioVideo,
  analytics,
  customModal,
  loginLimits,
  auditTrail,
});
