import { Component } from 'react';
import { format, differenceInSeconds } from 'date-fns';

interface IProps {
  lastUpdateTime: Date,
  onChange: () => void,
  timeToOnChange?: number,
}

const defaultProps = {
  timeToOnChange: 10,
};

class LastUpdate extends Component<IProps> {
  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  private intervalID: number | null | ReturnType<typeof setTimeout> = null;

  tick() {
    if (differenceInSeconds(new Date(), this.props.lastUpdateTime) > this.props.timeToOnChange - 1) {
      this.props.onChange();
    }
    this.forceUpdate();
  }

  render() {
    if (this.props.lastUpdateTime) {
      const timeLeft = this.props.timeToOnChange - differenceInSeconds(new Date(), this.props.lastUpdateTime);
      let formatStr;
      if (timeLeft > 60 * 60 * 24) {
        formatStr = "d 'days' HH:mm:ss";
      } else if (timeLeft > 60 * 60) {
        formatStr = 'HH:mm:ss';
      } else if (timeLeft > 60) {
        formatStr = 'mm:ss';
      } else {
        formatStr = 'ss';
      }

      return (
        <span>{ format(new Date(timeLeft * 1000), formatStr) }</span>
      );
    }
    return null;
  }
}

// @ts-ignore
LastUpdate.defaultProps = defaultProps;

export default LastUpdate;
