import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import DatabasesRepository from '@/condor/infrastructure/repository/admin/DatabasesRepository';
import { NotificationManager } from '@/ui/shared/components/Notification/index';
import * as actionTypes from './actionTypes';

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestDatabasesStructureSuccess(payload) {
  return {
    type: actionTypes.REQUEST_DATABASES_STRUCTURE_SUCCESS,
    payload,
  };
}

/**
 * @param {String} payload
 * @return {Object}
 */
function requestDatabasesStructureError(payload) {
  return {
    type: actionTypes.REQUEST_DATABASES_STRUCTURE_ERROR,
    payload,
  };
}

export function getDatabasesStructure() {
  return async (dispatch, getState) => {
    const databasesRepository = getState().container.get(DatabasesRepository);

    dispatch({ type: actionTypes.REQUEST_DATABASES_STRUCTURE });

    try {
      let drsDatabasesStructure = {
        mysql: {},
        postgres: {},
      };
      let finsightWebDatabasesStructure = {
        mysql: {},
        postgres: {},
      };
      let finsightStratsDatabasesStructure = {
        mysql: {},
        postgres: {},
      };
      let externalDatabasesStructure = {
        mysql: {},
        postgres: {},
      };
      let condorDatabasesStructure = {
        mysql: {},
        postgres: {},
      };

      let drs = null;
      try {
        drs = await databasesRepository.getDrsDatabasesStructure();
      } catch (errorResponse) {
        NotificationManager.error(getErrorMessage(errorResponse));
      }

      if (drs) {
        if (drs.mysql) {
          drsDatabasesStructure.mysql = drs.mysql;
        }
        if (drs.postgres) {
          drsDatabasesStructure.postgres = drs.postgres;
        }
      }

      let finsightWeb = null;
      try {
        finsightWeb = await databasesRepository.getFinsightWebDatabasesStructure();
      } catch (errorResponse) {
        NotificationManager.error(getErrorMessage(errorResponse));
      }

      if (finsightWeb) {
        if (finsightWeb.mysql) {
          finsightWebDatabasesStructure.mysql = finsightWeb.mysql;
        }
        if (finsightWeb.postgres) {
          finsightWebDatabasesStructure.postgres = finsightWeb.postgres;
        }
      }

      let finsightStrats = null;
      try {
        finsightStrats = await databasesRepository.getFinsightStratsDatabasesStructure();
      } catch (errorResponse) {
        NotificationManager.error(getErrorMessage(errorResponse));
      }

      if (finsightStrats) {
        if (finsightStrats.mysql) {
          finsightStratsDatabasesStructure.mysql = finsightStrats.mysql;
        }
        if (finsightStrats.postgres) {
          finsightStratsDatabasesStructure.postgres = finsightStrats.postgres;
        }
      }

      let external = null;
      try {
        external = await databasesRepository.getExternalDatabasesStructure();
      } catch (errorResponse) {
        NotificationManager.error(getErrorMessage(errorResponse));
      }

      if (external) {
        if (external.mysql) {
          externalDatabasesStructure.mysql = external.mysql;
        }
        if (external.postgres) {
          externalDatabasesStructure.postgres = external.postgres;
        }
      }

      let condor = null;
      try {
        condor = await databasesRepository.getCondorDatabasesStructure();
      } catch (errorResponse) {
        NotificationManager.error(getErrorMessage(errorResponse));
      }

      if (condor) {
        if (condor.mysql) {
          condorDatabasesStructure.mysql = condor.mysql;
        }
        if (condor.postgres) {
          condorDatabasesStructure.postgres = condor.postgres;
        }
      }

      dispatch(requestDatabasesStructureSuccess({
        drs: drsDatabasesStructure,
        finsight_web: finsightWebDatabasesStructure,
        finsight_strats: finsightStratsDatabasesStructure,
        external: externalDatabasesStructure,
        condor: condorDatabasesStructure,
      }));
    } catch (errorResponse) {
      dispatch(requestDatabasesStructureError(errorResponse));
      NotificationManager.error(getErrorMessage(errorResponse));
    }
  };
}

export function resetDatabasesStructure() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.DATABASES_STRUCTURE_RESET });
  };
}

export function changePath(payload) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DATABASES_STRUCTURE_CHANGE_PATH,
      payload,
    });
  };
}

export function changePathData(payload) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DATABASES_STRUCTURE_CHANGE_PATH_DATA,
      payload,
    });
  };
}
