import * as actionTypes from '../actionTypes';

const initialState = {
  initialValues: {},
  data: {},
  isFetching: false,
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DEAL_SLIDES_RESET:
      return initialState;
    case actionTypes.REQUEST_DEAL_SLIDES_UPDATE:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_DEAL_SLIDES:
      return {
        ...state,
        initialValues: {},
        data: {},
        isFetching: true,
      };
    case actionTypes.REQUEST_DEAL_SLIDES_SUCCESS:
    case actionTypes.REQUEST_DEAL_SLIDES_UPDATE_SUCCESS:
      return {
        initialValues: action.payload.initialValues,
        data: action.payload.data,
        isFetching: false,
      };
    case actionTypes.REQUEST_DEAL_SLIDES_ERROR:
    case actionTypes.REQUEST_DEAL_SLIDES_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
