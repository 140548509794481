import * as actionTypes from '../actionTypes';

const initialState = {
  initialValues: {},
  data: {},
  isFetching: false,
};

export default function audioVideo(state = initialState, action) {
  switch (action.type) {
    case actionTypes.AUDIO_VIDEO_RESET:
      return initialState;
    case actionTypes.REQUEST_AUDIO_VIDEO:
      return {
        ...state,
        initialValues: {},
        data: {},
        isFetching: true,
      };
    case actionTypes.REQUEST_AUDIO_VIDEO_SUCCESS:
      return {
        initialValues: action.payload.initialValues,
        data: action.payload.data,
        isFetching: false,
      };
    case actionTypes.REQUEST_AUDIO_VIDEO_UPDATE:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_AUDIO_VIDEO_ERROR:
    case actionTypes.REQUEST_AUDIO_VIDEO_UPDATE_SUCCESS:
    case actionTypes.REQUEST_AUDIO_VIDEO_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
