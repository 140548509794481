import { getCollectionReducer } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';

const newState = {
  sortBy: 'name',
  sortOrder: 'asc',
};

export default getCollectionReducer(
  actionTypes,
  newState,
  {
    [actionTypes.GET_COLLECTION_SUCCESS]: (state, action) => (
      {
        ...state,
        isFetching: false,
        data: action.payload.data,
        totalCount: action.payload.totalCount,
      }
    ),
    [actionTypes.GET_ACCOUNT_TYPES]: (state) => (
      {
        ...state,
        isFetching: true,
      }
    ),
    [actionTypes.GET_ACCOUNT_TYPES_SUCCESS]: (state, action) => (
      {
        ...state,
        isFetching: false,
        data: action.payload.collection,
        totalCount: action.payload.totalCount,
      }
    ),
    [actionTypes.GET_ACCOUNT_TYPES_ERROR]: (state) => (
      {
        ...state,
        isFetching: false,
      }
    ),
  },
);
