import submitDealModalStyles from '../SubmitDealModal/submitDealModal.scss';

export default [
  {
    name: 'trancheClass',
    title: 'Tranche',
    className: submitDealModalStyles.boldText,
  },
  {
    name: 'fieldLabel',
    title: 'Changed',
    className: submitDealModalStyles.boldText,
  },
  {
    name: 'oldValue',
    title: 'From',
    className: submitDealModalStyles.trancheChangeFromValue,
  },
  {
    name: 'newValue',
    title: 'To',
  },
];
