import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TechnicalSupport } from '../components';
import { setBreadcrumb, removeBreadcrumb } from '@/condor/ui/common/Breadcrumb/actions';

function mapStateToProps(state, ownProps) {
  state.match = ownProps;
  return {
    isFetching: state.technicalSupport.eventLogOffset.isFetching
                || state.technicalSupport.databases.structure.isFetching
                || state.technicalSupport.databases.current.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setBreadcrumb,
    removeBreadcrumb,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TechnicalSupport);
