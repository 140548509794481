import React from 'react';

interface IProps {
  pageTitle: string,
}

function PageTitle(props: IProps) {
  return (
    <h2>{ props.pageTitle }</h2>
  );
}

export default PageTitle;
