import * as actionTypes from './actionTypes';
import * as uploadTypes from '@/ui/shared/modules/upload/uploadTypes';

const initialState = {
  initialFile: null,
  file: {},
  process: {
    progress: 0,
    status: '',
    uuid: '',
  },
  uploadFile: null,
  callback: null,
  name: '',
  uploaded: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPLOAD_FINSIGHT_IMPORT_COMPANY_ADD:
      return {
        ...state,
        initialFile: null,
        file: {},
        process: {
          progress: 0,
          status: uploadTypes.UPLOAD_START,
          uuid: action.payload.uuid,
        },
        uploadFile: action.payload.uploadFile,
        name: action.payload.uploadFile.name,
        callback: action.payload.callback,
        uploaded: false,
      };

    case actionTypes.UPLOAD_FINSIGHT_IMPORT_COMPANY_ERROR:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            status: uploadTypes.UPLOAD_ERROR,
          },
          uploaded: false,
          canSave: false,
        };
      }
      return state;

    case actionTypes.UPLOAD_FINSIGHT_IMPORT_COMPANY_CHUNK_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            progress: parseInt(action.payload.progress),
            status: uploadTypes.UPLOAD_CHUNK_SUCCESS,
            uuid: action.payload.uuid,
          },
        };
      }
      return state;
    case actionTypes.UPLOAD_FINSIGHT_IMPORT_COMPANY_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            status: uploadTypes.UPLOAD_SUCCESS,
          },
          uploaded: true,
        };
      }
      return state;

    case actionTypes.UPLOAD_FINSIGHT_IMPORT_COMPANY_PROCESSING:
      if (state.process.uuid === action.payload.uuid &&
          state.process.status !== uploadTypes.UPLOAD_DONE) {
        return {
          ...state,
          process: {
            ...state.process,
            progress: 0,
            status: uploadTypes.UPLOAD_PROCESSING_START,
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_FINSIGHT_IMPORT_COMPANY_DONE:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          file: {
            path: action.payload.data[0],
          },
          process: {
            ...state.process,
            progress: 100,
            status: uploadTypes.UPLOAD_DONE,
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_FINSIGHT_IMPORT_COMPANY_CANCEL_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return initialState;
      }
      return state;

    case actionTypes.UPLOAD_FINSIGHT_IMPORT_COMPANY_RESET:
    case actionTypes.UPLOAD_CLEAR:
      return initialState;

    default:
      return state;
  }
}
