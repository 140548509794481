import { getItemReducer } from '@/Framework/State/Redux/itemFactory';
import actionTypes from './actionTypes';

const newState = {
  isInlineEditing: false,
};

export default getItemReducer(
  actionTypes,
  newState,
  {
    [actionTypes.SET_CONDOR_CONTACTS_IS_INLINE_EDITING]: (state, action) => (
      { ...state, isInlineEditing: action.payload }
    ),
  },
);
