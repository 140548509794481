import { combineReducers } from 'redux';
import * as components from './components';
import * as containers from './containers';
import tableDataReducer from '@/condor/application/actions/databases/current/reducer';
import databasesStructureReducer from '@/condor/application/actions/databases/structure/reducer';
import queryFilterFromReducer from '@/condor/application/actions/databases/queryFilterForm/reducer';

export default {
  components,
  containers,
  reducers: combineReducers({
    queryFilterForm: queryFilterFromReducer,
    structure: databasesStructureReducer,
    current: tableDataReducer,
  }),

};
