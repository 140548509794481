import { combineReducers } from 'redux';
import deals from './deals/reducer';
import leis from './leis/reducer';
import allocations from './allocations/reducer';
import evercalls from './evercalls/reducer';
import notes from './notes/reducer';
import contacts from './contacts/reducer';
import emailDomains from './emailDomains/reducer';
import webDomains from './webDomains/reducer';
import metadata from './metadata/reducer';

export default combineReducers({
  deals,
  leis,
  allocations,
  evercalls,
  notes,
  contacts,
  emailDomains,
  webDomains,
  metadata,
});
