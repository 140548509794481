import { Component } from 'react';
import { JSONTree } from 'react-json-tree';
import { Modal } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';

interface IProps {
  onClose: () => void,
  showItem: { [key: string]: any },
}

class PreviewCellModal extends Component<IProps> {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.isVisible = this.isVisible.bind(this);
    this.getItem = this.getItem.bind(this);
    this.getJsonItem = this.getJsonItem.bind(this);
    this.isJson = this.isJson.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  getTitle() {
    return this.props.showItem.title;
  }

  getItem() {
    return this.props.showItem.data;
  }

  getJsonItem() {
    if (typeof this.props.showItem.data === 'string') {
      return JSON.parse(this.props.showItem.data);
    }

    return this.getItem();
  }

  isVisible() {
    return !!this.props.showItem;
  }

  isJson() {
    return this.props.showItem.isJson;
  }

  /**
   * @return {ReactElement}
   */
  render() {
    if (!this.isVisible()) {
      return null;
    }
    return (
      <div className="json-field-modal">
        <Modal
          onCloseClicked={ () => this.onClose() }
          isVisible
          title={ this.getTitle() }
          dataTest="previewCellModal"
          footer={ (
            <Button
              // @ts-ignore
              type={ variantTypes.secondary }
              onClick={ () => this.onClose() }
              title="Close"
              dataTest="closeButton"
            />
          ) }
        >
          { !this.isJson() && !!this.getItem() && (
            <div
              dangerouslySetInnerHTML={ { __html: this.getItem().replace(/(?:\r\n|\r|\n){2}/g, '<br /><br />') } }
            />
          ) }
          { this.isJson() && (
            <JSONTree
              data={ { [this.getTitle()]: this.getJsonItem() } }
              shouldExpandNodeInitially={ (keyName, data, level) => level <= 3 }
              hideRoot
              invertTheme={ false }
            />
          ) }
        </Modal>
      </div>
    );
  }
}

export default PreviewCellModal;
