import * as actionTypes from './actionTypes';

const initialState = {
  isExporting: false,
  data: [],
  isFetching: false,
  totalCount: 0,
  sortBy: 'lastLoginAt',
  sortOrder: 'desc',
  page: 1,
  perPage: 25,
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_MANAGER_ANALYTICS_CONTACTS:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_MANAGER_ANALYTICS_CONTACTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.collection,
        totalCount: action.payload.totalCount,
      };
    case actionTypes.GET_MANAGER_ANALYTICS_CONTACTS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.SET_MANAGER_ANALYTICS_CONTACTS_TABLE_SORT:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
        page: 1,
      };
    case actionTypes.SET_MANAGER_ANALYTICS_CONTACTS_TABLE_ITEMS_PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
        page: 1,
      };
    case actionTypes.SET_MANAGER_ANALYTICS_CONTACTS_TABLE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case actionTypes.EXPORT_TO_EXCEL_MANAGER_ANALYTICS_CONTACTS:
      return {
        ...state,
        isExporting: true,
      };
    case actionTypes.EXPORT_TO_EXCEL_MANAGER_ANALYTICS_CONTACTS_SUCCESS:
    case actionTypes.EXPORT_TO_EXCEL_MANAGER_ANALYTICS_CONTACTS_ERROR:
      return {
        ...state,
        isExporting: false,
      };
    case actionTypes.RESET_MANAGER_ANALYTICS_CONTACTS:
      return initialState;
    default:
      return state;
  }
}
