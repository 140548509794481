import { getCollectionActionTypes } from '@/Framework/State/Redux/collectionFactory';

export default {
  ...getCollectionActionTypes(
    'CONDOR_ACCOUNTS_STATS',
    { withSort: true, withSearch: true },
  ),
  TOGGLE_BULK_DELETE: 'TOGGLE_BULK_DELETE',

  BULK_DELETE_ACCOUNTS: 'BULK_DELETE_ACCOUNTS',
  BULK_DELETE_ACCOUNTS_SUCCESS: 'BULK_DELETE_ACCOUNTS_SUCCESS',
  BULK_DELETE_ACCOUNTS_ERROR: 'BULK_DELETE_ACCOUNTS_ERROR',
};
