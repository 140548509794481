import * as actionTypes from './actionTypes';
import createAction from '@/Framework/State/Redux/createAction';

/**
 * @param {String} payload
 * @return {function(*)}
 */
export function setPageTitle(payload) {
  return (dispatch) => {
    dispatch(createAction(actionTypes.PAGE_TITLE_SET, payload));
  };
}
