export default function convertFromFormsArrayToJsonQuery(forms) {
  let result = {};
  forms = cleanEmptyBlock(forms);

  if (forms.length === 1) {
    result = setForm(forms[0]);
  } else {
    forms.forEach((form, index) => {
      let formLo = null;
      if (index === 0) {
        formLo = forms[index + 1];
      } else {
        formLo = form;
      }
      let groupResult = {};
      if (form.groups.length === 1) {
        groupResult = setGroup(form.groups[0]);
      } else {
        groupResult = setForm(form);
      }
      if (!result[formLo.lo]) {
        result[formLo.lo] = [];
      }
      result[formLo.lo] = [
        ...result[formLo.lo],
        groupResult,
      ];
    });

    Object.keys(result).forEach((lo) => {
      if (result[lo].length === 1) {
        // eslint-disable-next-line prefer-destructuring
        result[lo] = result[lo][0];
      }
    });
  }

  return result;
}

function setForm(form) {
  let result = null;
  if (form.groups.length === 1) {
    result = setGroup(form.groups[0]);
  } else {
    result = {};
    form.groups.forEach((group, index) => {
      let groupLo = null;
      if (index === 0) {
        groupLo = form.groups[index + 1];
      } else {
        groupLo = group;
      }
      if (!result[groupLo.lo]) {
        result[groupLo.lo] = [];
      }
      result[groupLo.lo] = [
        ...result[groupLo.lo],
        setGroup(group),
      ];
    });
    Object.keys(result).forEach((lo) => {
      if (result[lo].length === 1) {
        // eslint-disable-next-line prefer-destructuring
        result[lo] = result[lo][0];
      }
    });
  }
  return result;
}

function setGroup(group) {
  let result = null;
  if (group.children.length === 1) {
    result = {};
    let child = group.children[0];
    let suffix = child.groupSuffix === undefined ? '' : child.groupSuffix;
    result[`${ group.label }${ suffix }`] = {
      [child.co]: child.value === undefined ? '' : child.value,
    };
  } else {
    result = eachChildren(group);
  }
  return result;
}

function eachChildren(group) {
  let childrenResult = {};
  group.children.forEach((child, index) => {
    let childLo = null;
    if (index === 0) {
      childLo = group.children[index + 1];
    } else {
      childLo = child;
    }
    if (!childrenResult[childLo.lo]) {
      childrenResult[childLo.lo] = [];
    }
    let suffix = child.groupSuffix === undefined ? '' : child.groupSuffix;
    childrenResult[childLo.lo].push({
      [`${ group.label }${ suffix }`]: {
        [child.co]: child.value === undefined ? '' : child.value,
      },
    });
  });
  Object.keys(childrenResult).forEach((lo) => {
    if (childrenResult[lo].length === 1) {
      // eslint-disable-next-line prefer-destructuring
      childrenResult[lo] = childrenResult[lo][0];
    }
  });
  return childrenResult;
}

function cleanEmptyBlock(forms) {
  forms.forEach((form, formIndex) => {
    if (form.groups.length !== 0) {
      form.groups.forEach((group, groupIndex) => {
        if (group.children.length === 0) {
          delete forms[formIndex].groups[groupIndex];
        }
      });
    } else {
      delete forms[formIndex];
    }
  });

  forms = forms.filter((form) => !!form);
  let result = [];
  forms.forEach((form) => {
    let groups = form.groups.filter((group) => !!group);
    if (groups.length) {
      result.push({
        ...form,
        groups,
      });
    }
  });
  return result;
}
