import { Component } from 'react';
import config from '@/Framework/config';
import DatabaseTableItemListContainer from '../DatabaseTableItemList/DatabaseTableItemListContainer';
import DataSourceStructureModalContainer from '../containers/DataSourceStructureModalContainer';
import PageTitle from '@/condor/ui/common/PageTitle/PageTitleContainer';
import Breadcrumb from '@/condor/ui/common/Breadcrumb/BreadcrumbContainer';

interface IProps {
  initStructurePath: (option: {
    [key: string]: any,
  }) => void,
  setBreadcrumb: (option: {
    name: string,
    url: string,
  }) => void,
  removeBreadcrumb: (breadcrumb: string) => void,
  match: { [key: string]: any },
}

class Databases extends Component<IProps> {
  componentDidMount() {
    this.props.setBreadcrumb({
      name: 'Databases',
      url: `${ config.condor.base }/technical/databases`,
    });
    const { dataSource, database, table } = this.props.match.params;
    if (!!dataSource && !!database && !!table) {
      this.props.initStructurePath([
        dataSource,
        database,
        table,
      ]);
    }
  }

  componentDidUpdate() {
    if (
      !this.props.match.params.dataSource
      || !this.props.match.params.database
      || !this.props.match.params.table
    ) {
      this.props.initStructurePath([]);
    }
  }

  componentWillUnmount() {
    this.props.removeBreadcrumb(`${ config.condor.base }/technical/databases`);
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <>
        <>
          <PageTitle />
          <Breadcrumb />
        </>
        <DataSourceStructureModalContainer />
        <DatabaseTableItemListContainer />
      </>
    );
  }
}

export default Databases;
