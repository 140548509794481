import * as actionTypes from './actionTypes';
import { createAccountsOptions } from '../helpers';

const initialState = {
  isFetching: false,
  isMappingsModalVisible: false,
  isMappingsProgressModalVisible: false,
  mappingsTitle: '',
  mappingsTypes: [],
  accountsOptions: [],
  remappedItemCounts: {
    contacts: 0,
    allocations: 0,
    evercalls: 0,
    leis: 0,
    notes: 0,
    emailDomains: 0,
    webDomains: 0,
    dealsdealroadshow: 0,
    dealsinvestorset: 0,
    deals17g5: 0,
    dealsdealvdr: 0,
  },
  isFinished: false,
  isError: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_REMAPPING_PROCESS:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.START_REMAPPING_PROCESS_SUCCESS:
    case actionTypes.START_REMAPPING_PROCESS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.OPEN_CONDOR_ACCOUNT_MAPPING_MODAL:
      return {
        ...state,
        isMappingsModalVisible: true,
        mappingsTitle: action.payload.mappingsTitle,
        mappingsTypes: action.payload.mappingsTypes,
      };
    case actionTypes.CLOSE_CONDOR_ACCOUNT_MAPPING_MODAL:
      return {
        ...state,
        isFetching: false,
        isMappingsModalVisible: false,
        mappingsTitle: '',
        accountsOptions: [],
      };
    case actionTypes.OPEN_CONDOR_ACCOUNT_MAPPING_PROGRESS_MODAL:
      return {
        ...state,
        isMappingsProgressModalVisible: true,
      };
    case actionTypes.CLOSE_CONDOR_ACCOUNT_MAPPING_PROGRESS_MODAL:
      return {
        ...initialState,
      };
    case actionTypes.GET_CONDOR_FILTERED_ACCOUNTS_OPTIONS_SUCCESS:
      return {
        ...state,
        accountsOptions: action.payload.collection.map(createAccountsOptions),
      };
    case actionTypes.REMAP_PROCESS_UPDATE:
      return {
        ...state,
        remappedItemCounts: {
          ...state.remappedItemCounts,
          [action.payload.migrationType]: action.payload.migratedItemsCount,
        },
      };
    case actionTypes.REMAP_PROCESS_FINISHED:
      return {
        ...state,
        isError: action.payload.hasError,
        isFinished: true,
      };
    case actionTypes.RESET_MAPPINGS_METADATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
