import actionTypes from './actionTypes';

const initialState = {
  manageSubscriptionsModalData: null,
  isSubscribeModalVisible: false,
  isSubmitting: false,
  subscriptionsData: [],
  subscriptionsFormValues: {},
  isFetching: false,
};

const metadata = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MANAGE_SUBSCRIPTION_MODAL_DATA:
      return {
        ...state,
        manageSubscriptionsModalData: action.payload,
      };
    case actionTypes.SET_IS_SUBSCRIBE_MODAL_VISIBLE:
      return {
        ...state,
        isSubscribeModalVisible: action.payload,
      };
    case actionTypes.CONDOR_FINSIGHT_SUBSCRIBE:
      return {
        ...state,
        isSubmitting: true,
      };
    case actionTypes.CONDOR_FINSIGHT_SUBSCRIBE_ERROR:
      return {
        ...state,
        isSubmitting: false,
      };
    case actionTypes.CONDOR_FINSIGHT_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case actionTypes.GET_SUBSCRIPTIONS:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        subscriptionsData: action.payload.subscriptionsData,
        subscriptionsFormValues: action.payload.subscriptionsFormValues,
      };
    case actionTypes.GET_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.RESET_METADATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default metadata;
