import { differenceInSeconds, format } from 'date-fns';

export function diffTimeFormat(timeNow, timeLeft = new Date()) {
  let time = differenceInSeconds(timeNow, timeLeft);
  let formatStr;
  if (time > 60 * 60 * 24) {
    formatStr = "dd 'days' hh:mm:ss";
  } else if (time > 60 * 60) {
    formatStr = 'hh:mm:ss';
  } else {
    formatStr = 'mm:ss';
  }

  return format(new Date(time * 1000), formatStr);
}
