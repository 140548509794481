export const REQUEST_ROADSHOWS = 'REQUEST_GET_ROADSHOWS';
export const REQUEST_ROADSHOWS_SUCCESS = 'REQUEST_ROADSHOWS_SUCCESS';
export const REQUEST_ROADSHOWS_ERROR = 'REQUEST_ROADSHOWS_ERROR';
export const REQUEST_ROADSHOWS_TOTAL_COUNT = 'REQUEST_ROADSHOWS_TOTAL_COUNT';
export const ROADSHOWS_RESET = 'ROADSHOWS_RESET';

export const ROADSHOWS_TABLE_FILTER_SEARCH = 'ROADSHOWS_TABLE_FILTER_SEARCH';
export const ROADSHOWS_TABLE_FILTER_PAGINATION = 'ROADSHOWS_TABLE_FILTER_PAGINATION';
export const ROADSHOWS_TABLE_FILTER_PER_PAGE = 'ROADSHOWS_TABLE_FILTER_PER_PAGE';
export const ROADSHOWS_TABLE_FILTER_RADIO = 'ROADSHOWS_TABLE_FILTER_RADIO';
export const ROADSHOWS_TABLE_SORT = 'ROADSHOWS_TABLE_SORT';
export const ROADSHOWS_TABLE_RESET = 'ROADSHOWS_TABLE_RESET';

export const REQUEST_TOGGLE_ROADSHOWS_ACTIONS = 'REQUEST_TOGGLE_ROADSHOWS_ACTIONS';
export const REQUEST_TOGGLE_ROADSHOWS_ACTIONS_SUCCESS = 'REQUEST_TOGGLE_ROADSHOWS_ACTIONS_SUCCESS';
export const REQUEST_TOGGLE_ROADSHOWS_ACTIONS_ERROR = 'REQUEST_TOGGLE_ROADSHOWS_ACTIONS_ERROR';
