import * as actionTypes from './actionTypes';

const initialState = {
  correctionData: {},
  process: {
    progress: 0,
    status: '',
    payload: {},
  },
  table: {
    header: {},
    body: {},
  },
  emptyBodyRow: {},
  bodyExistsColumn: {},
  uiCorrectionOnly: false,
  canReimportFile: false,
  validationTableVisible: false,
  companySelectModalVisible: false,
  companySelectModalCollection: [],
  companyCreateModalVisible: false,
  companyCreateModalCompany: {},
  activeValidateCell: null,
};
const numberColumnIndex = 0;
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_PAYLOAD:
      return {
        ...state,
        process: {
          ...state.process,
          status: action.payload.status,
          payload: {
            ...action.payload,
          },
        },
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_SET_CORRECTION_DATA:
      return {
        ...state,
        correctionData: action.payload,
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_NEED_CORRECTION_FLAG:
      return {
        ...state,
        uiCorrectionOnly: action.payload,
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_CAN_REIMPORT_CHANGE:
      return {
        ...state,
        canReimportFile: action.payload,
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_TABLE_VISIBLE:
      return {
        ...state,
        validationTableVisible: action.payload,
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_COMPANY_SELECT_VISIBLE:
      return {
        ...state,
        companySelectModalVisible: action.payload,
      };

    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_COMPANY_COLLECTION_CHANGE:
      return {
        ...state,
        companySelectModalCollection: action.payload,
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_COMPANY_CREATE_VISIBLE:
      return {
        ...state,
        companyCreateModalVisible: action.payload,
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_COMPANY_CREATE_DATA_INIT:
      return {
        ...state,
        companyCreateModalCompany: action.payload,
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_ACTIVE_VALIDATE_CELL_CHANGE:
      return {
        ...state,
        activeValidateCell: action.payload,
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_SET_HEADER:
      let emptyBodyRow = {};
      let header = {};
      header[numberColumnIndex] = {
        name: '#',
        numberColumn: true,
        required: false,
        canUiCorrection: false,
      };
      Object.keys(action.payload.data).forEach((index) => {
        let column = action.payload.data[index];

        header[column.position.columnIndex] = {
          name: column.name,
          required: column.required,
          canUiCorrection: column.canUiCorrection,
        };
        emptyBodyRow[column.position.columnIndex] = {
          value: null,
          correctionType: null,
          data: [],
        };
      });
      return {
        ...state,
        table: {
          ...state.table,
          header,
        },
        emptyBodyRow,
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_SET_BODY_ROWS:
      return {
        ...state,
        table: {
          ...state.table,
          body: {
            ...action.payload,
          },
        },
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_ADD_BODY_ROWS:
      let body = {};
      let bodyExistsColumn = { ...state.bodyExistsColumn };
      Object.keys(action.payload.data).forEach((rowIndex) => {
        let cells = action.payload.data[rowIndex];

        body[rowIndex] = { ...state.emptyBodyRow };
        body[rowIndex][numberColumnIndex] = {
          corrected: false,
          numberColumn: true,
          value: rowIndex,
          correctionType: '',
          data: {},
          position: { rowIndex, columnIndex: numberColumnIndex },
        };
        Object.keys(cells).forEach((columnIndex) => {
          let cell = cells[columnIndex];
          if (!bodyExistsColumn[cell.position.columnIndex]) {
            bodyExistsColumn[cell.position.columnIndex] = true;
          }
          body[cell.position.rowIndex][cell.position.columnIndex] = {
            corrected: false,
            numberColumn: false,
            value: cell.value,
            correctionType: cell.correctionType,
            data: cell.data,
            position: cell.position,
          };
        });
      });
      return {
        ...state,
        table: {
          ...state.table,
          body: {
            ...state.table.body,
            ...body,
          },
        },
        bodyExistsColumn,
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_RESET_WITHOUT_CORRECTION_DATA:
      return {
        ...initialState,
        correctionData: state.correctionData,
      };
    case actionTypes.CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_RESET:
      return initialState;
    default:
      return state;
  }
}
