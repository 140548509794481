import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPageTitle } from '../PageTitle/actions';
import Breadcrumb from './Breadcrumb';

function mapStateToProps(state) {
  return {
    breadcrumb: state.breadcrumb,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setPageTitle,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Breadcrumb);
