import * as actionTypes from '../actionTypes';

const initialState = {
  initialValues: {},
  newRoadshow: {},
  data: {},
  isFetching: false,
};

export default function customModal(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CUSTOM_MODAL_RESET:
      return initialState;
    case actionTypes.REQUEST_CUSTOM_MODAL:
    case actionTypes.REQUEST_ROADSHOW_CUSTOM_MODAL:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_CUSTOM_MODAL_SUCCESS:
      return {
        ...state,
        initialValues: action.payload.initialValues,
        data: action.payload.data,
        isFetching: false,
      };
    case actionTypes.REQUEST_ROADSHOW_CUSTOM_MODAL_SUCCESS:
      return {
        ...state,
        newRoadshow: action.payload,
        isFetching: false,
      };
    case actionTypes.REQUEST_ROADSHOW_CUSTOM_MODAL_RESET:
      return {
        ...state,
        newRoadshow: {},
        isFetching: false,
      };
    case actionTypes.REQUEST_ROADSHOW_CUSTOM_MODAL_ERROR:
    case actionTypes.REQUEST_CUSTOM_MODAL_ERROR:
    case actionTypes.REQUEST_CUSTOM_MODAL_UPDATE_SUCCESS:
    case actionTypes.REQUEST_CUSTOM_MODAL_UPDATE_ERROR:
    case actionTypes.REQUEST_CUSTOM_MODAL_UPDATE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
