import { push } from 'react-router-redux';
import { generatePath } from 'react-router';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import DatabasesRepository from '@/condor/infrastructure/repository/admin/DatabasesRepository';
import { NotificationManager } from '@/ui/shared/components/Notification/index';
import * as actionTypes from './actionTypes';

/**
 * @param {String} sortBy
 * @param {String} sortOrder
 * @return {Object}
 */
export function sortTableData(sortBy, sortOrder) {
  return {
    type: actionTypes.TABLE_DATA_SORT_CHANGE,
    payload: {
      sortBy,
      sortOrder,
    },
  };
}

/**
 * @param {Number} page
 * @return {Object}
 */
export function paginateTableData(page) {
  return {
    type: actionTypes.TABLE_DATA_PAGE_CHANGE,
    payload: {
      page,
    },
  };
}

/**
 * @param {Number} perPage
 * @return {Object}
 */
export function itemsPerPageTableData(perPage) {
  return {
    type: actionTypes.TABLE_DATA_ITEMS_PER_PAGE_CHANGE,
    payload: {
      perPage,
    },
  };
}

/**
 * @param {Array} payload
 * @return {Object}
 */
function requestTableDataSuccess(payload) {
  return {
    type: actionTypes.REQUEST_TABLE_DATA_SUCCESS,
    payload,
  };
}

/**
 * @param {Number} payload
 * @return {Object}
 */
function requestTableDataTotcalCountSuccess(payload) {
  return {
    type: actionTypes.REQUEST_TABLE_DATA_TOTAL_COUNT,
    payload,
  };
}

/**
 * @param {String} payload
 * @return {Object}
 */
function requestTableDataError(payload) {
  return {
    type: actionTypes.REQUEST_TABLE_DATA_ERROR,
    payload,
  };
}

export function getTableData(paramsToRequest) {
  return async (dispatch, getState) => {
    const databasesRepository = getState().container.get(DatabasesRepository);

    dispatch({ type: actionTypes.REQUEST_TABLE_DATA });

    try {
      let { structure } = getState().technicalSupport.databases;
      let tableData = null;
      if (
        paramsToRequest.dataSource in structure.finsight_web &&
        paramsToRequest.database in structure.finsight_web[paramsToRequest.dataSource]
      ) {
        tableData = await databasesRepository.getFinsightWebTableDataByFilter(paramsToRequest);
      }
      if (
        paramsToRequest.dataSource in structure.finsight_strats &&
        paramsToRequest.database in structure.finsight_strats[paramsToRequest.dataSource]
      ) {
        tableData = await databasesRepository.getFinsightStratsTableDataByFilter(paramsToRequest);
      }
      if (
        paramsToRequest.dataSource in structure.drs &&
        paramsToRequest.database in structure.drs[paramsToRequest.dataSource]
      ) {
        tableData = await databasesRepository.getDrsTableDataByFilter(paramsToRequest);
      }
      if (
        paramsToRequest.dataSource in structure.external &&
        paramsToRequest.database in structure.external[paramsToRequest.dataSource]
      ) {
        tableData = await databasesRepository.getExternalTableDataByFilter(paramsToRequest);
      }
      if (
        paramsToRequest.dataSource in structure.condor &&
        paramsToRequest.database in structure.condor[paramsToRequest.dataSource]
      ) {
        tableData = await databasesRepository.getCondorTableDataByFilter(paramsToRequest);
      }
      if (tableData) {
        dispatch(requestTableDataSuccess(tableData.collection));
        dispatch(requestTableDataTotcalCountSuccess(tableData.totalCount));
      }
    } catch (errorResponse) {
      dispatch(requestTableDataError(errorResponse));
      NotificationManager.error(getErrorMessage(errorResponse));
    }
  };
}

export function resetTableData() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.TABLE_DATA_RESET });
  };
}

export function goToDatabase(dataSource, database, table) {
  return async (dispatch, getState) => {
    let { match } = getState();
    dispatch(push(generatePath(match.path, { ...match.params, dataSource, database, table })));
  };
}
