export const GET_SLIDE_TYPE = 'GET_SLIDE_TYPE';
export const REQUEST_DEAL_SLIDES = 'REQUEST_DEAL_SLIDES';
export const REQUEST_DEAL_SLIDES_SUCCESS = 'REQUEST_DEAL_SLIDES_SUCCESS';
export const REQUEST_DEAL_SLIDES_ERROR = 'REQUEST_DEAL_SLIDES_ERROR';
export const REQUEST_DEAL_SLIDES_UPDATE = 'REQUEST_DEAL_SLIDES_UPDATE';
export const REQUEST_DEAL_SLIDES_UPDATE_SUCCESS = 'REQUEST_DEAL_SLIDES_UPDATE_SUCCESS';
export const REQUEST_DEAL_SLIDES_UPDATE_ERROR = 'REQUEST_DEAL_SLIDES_UPDATE_ERROR';
export const DEAL_SLIDES_RESET = 'DEAL_SLIDES_RESET';

export const SET_DEAL_SLIDES_NEED_TO_REFRESH_MODAL_VISIBLE = 'SET_DEAL_SLIDES_NEED_TO_REFRESH_MODAL_VISIBLE';
