import { combineReducers } from 'redux';
import company from './company/reducer';
import industry from './industry/reducer';
import deal from './deal/reducer';
import subscriptions from './subscriptions/reducer';
import dealNoPricing from './dealNoPricingDate/reducer';
import filings from './filings/reducer';
import finsightImport from './import/reducer';

export default combineReducers(
  {
    company,
    industry,
    subscriptions,
    deal,
    dealNoPricing,
    filings,
    import: finsightImport,
  },
);
