import { combineReducers } from 'redux';
import data from './data';
import slideTypes from './slideTypes';
import needToRefreshModal from './needToRefreshModal';

export default combineReducers({
  data,
  slideTypes,
  needToRefreshModal,
});
