export const UPLOAD_CLEAR = 'UPLOAD_CLEAR';

// Presentation cue points
export const UPLOAD_FINSIGHT_IMPORT_DEAL_ADD = 'UPLOAD_FINSIGHT_IMPORT_DEAL_ADD';
export const UPLOAD_FINSIGHT_IMPORT_DEAL_CHUNK_SUCCESS = 'UPLOAD_FINSIGHT_IMPORT_DEAL_CHUNK_SUCCESS';
export const UPLOAD_FINSIGHT_IMPORT_DEAL_SUCCESS = 'UPLOAD_FINSIGHT_IMPORT_DEAL_SUCCESS';
export const UPLOAD_FINSIGHT_IMPORT_DEAL_ERROR = 'UPLOAD_FINSIGHT_IMPORT_DEAL_ERROR';
export const UPLOAD_FINSIGHT_IMPORT_DEAL_PROCESSING = 'UPLOAD_FINSIGHT_IMPORT_DEAL_PROCESSING';
export const UPLOAD_FINSIGHT_IMPORT_DEAL_DONE = 'UPLOAD_FINSIGHT_IMPORT_DEAL_DONE';
export const UPLOAD_FINSIGHT_IMPORT_DEAL_CANCEL = 'UPLOAD_FINSIGHT_IMPORT_DEAL_CANCEL';
export const UPLOAD_FINSIGHT_IMPORT_DEAL_CANCEL_SUCCESS = 'UPLOAD_FINSIGHT_IMPORT_DEAL_CANCEL_SUCCESS';
export const UPLOAD_FINSIGHT_IMPORT_DEAL_RESET = 'UPLOAD_FINSIGHT_IMPORT_DEAL_RESET';
