import { getCollectionReducer } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';

const initialState = {
  isDeleteImportModalVisible: false,
  deleteItem: {},
};

export default getCollectionReducer(
  actionTypes,
  initialState,
  {
    [actionTypes.SET_CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL_DELETE_ITEM]: (state, action) => (
      {
        ...state,
        deleteItem: action.payload,
      }
    ),
    [actionTypes.CHANGE_CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL_DELETE_MODAL_VISIBLE]: (state, action) => (
      {
        ...state,
        isDeleteImportModalVisible: action.payload,
      }
    ),
  },
);
