export const UPLOAD_CLEAR = 'UPLOAD_CLEAR';

// Presentation cue points
export const UPLOAD_FINSIGHT_IMPORT_BOND_ADD = 'UPLOAD_FINSIGHT_IMPORT_BOND_ADD';
export const UPLOAD_FINSIGHT_IMPORT_BOND_CHUNK_SUCCESS = 'UPLOAD_FINSIGHT_IMPORT_BOND_CHUNK_SUCCESS';
export const UPLOAD_FINSIGHT_IMPORT_BOND_SUCCESS = 'UPLOAD_FINSIGHT_IMPORT_BOND_SUCCESS';
export const UPLOAD_FINSIGHT_IMPORT_BOND_ERROR = 'UPLOAD_FINSIGHT_IMPORT_BOND_ERROR';
export const UPLOAD_FINSIGHT_IMPORT_BOND_PROCESSING = 'UPLOAD_FINSIGHT_IMPORT_BOND_PROCESSING';
export const UPLOAD_FINSIGHT_IMPORT_BOND_DONE = 'UPLOAD_FINSIGHT_IMPORT_BOND_DONE';
export const UPLOAD_FINSIGHT_IMPORT_BOND_CANCEL = 'UPLOAD_FINSIGHT_IMPORT_BOND_CANCEL';
export const UPLOAD_FINSIGHT_IMPORT_BOND_CANCEL_SUCCESS = 'UPLOAD_FINSIGHT_IMPORT_BOND_CANCEL_SUCCESS';
export const UPLOAD_FINSIGHT_IMPORT_BOND_RESET = 'UPLOAD_FINSIGHT_IMPORT_BOND_RESET';
