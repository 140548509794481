import { applyValidatorToMultipleFields } from '@/ui/shared/validators/helpers';
import { validateRequiredField } from '@/ui/shared/validators/validateRequiredField';
import { PARENT, ISSUER, UNDERWRITER, DEALER } from '@/finsight/application/actions/pageFilters/constants';

export const fieldsData = {
  PARENT: {
    active: {
      name: 'parent',
      label: 'Select new parent / sponsor company',
      isSelect: true,
      role: PARENT,
      dataTest: 'selectNewParent',
    },
    disabled: {
      name: 'disabledParent',
      label: 'Select new parent / sponsor company',
      placeholder: 'Not applicable',
      disabled: true,
      dataTest: 'selectNewParent',
    },
  },
  ISSUER: {
    active: {
      name: 'issuer',
      label: 'Select new issuer company',
      isSelect: true,
      role: ISSUER,
      dataTest: 'selectNewIssuer',
    },
    disabled: {
      name: 'disabledIssuer',
      label: 'Select new issuer company',
      placeholder: 'Not applicable',
      disabled: true,
      dataTest: 'selectNewIssuer',
    },
  },
  UNDERWRITER: {
    active: {
      name: 'underwriter',
      label: 'Select new underwriter company',
      isSelect: true,
      role: UNDERWRITER,
      dataTest: 'selectNewUnderwriter',
    },
    disabled: {
      name: 'disabledUnderwriter',
      label: 'Select new underwriter company',
      placeholder: 'Not applicable',
      disabled: true,
      dataTest: 'selectNewUnderwriter',
    },
  },
  DEALER: {
    active: {
      name: 'dealer',
      label: 'Select new dealer company',
      isSelect: true,
      role: DEALER,
      dataTest: 'selectNewDealer',
    },
    disabled: {
      name: 'disabledDealer',
      label: 'Select new dealer company',
      placeholder: 'Not applicable',
      disabled: true,
      dataTest: 'selectNewDealer',
    },
  },
  RESEARCH_ISSUER: {
    active: {
      name: 'researchIssuer',
      label: 'Select new research issuer company',
      isSelect: true,
      role: ISSUER,
      dataTest: 'selectNewResearchIssuer',
    },
    disabled: {
      name: 'disabledResearchIssuer',
      label: 'Select new research issuer company',
      placeholder: 'Not applicable',
      disabled: true,
      dataTest: 'selectNewResearchIssuer',
    },
  },
  RESEARCH_PARENT: {
    active: {
      name: 'researchParent',
      label: 'Select new research parent company',
      isSelect: true,
      role: PARENT,
      dataTest: 'selectNewResearchParent',
    },
    disabled: {
      name: 'disabledResearchParent',
      label: 'Select new research parent company',
      placeholder: 'Not applicable',
      disabled: true,
      dataTest: 'selectNewResearchParent',
    },
  },
};

const formatFieldForValidator = (field) => ({ fieldName: field.name, fieldCaption: field.label, ...field });
export default (values) => applyValidatorToMultipleFields(
  {
    values,
    validator: validateRequiredField,
    fields: [
      fieldsData.DEALER.active,
      fieldsData.ISSUER.active,
      fieldsData.UNDERWRITER.active,
      fieldsData.PARENT.active,
      fieldsData.RESEARCH_ISSUER.active,
      fieldsData.RESEARCH_PARENT.active,
    ].map(formatFieldForValidator),
  },
);
