export const REQUEST_LOGIN_LIMITS = 'REQUEST_LOGIN_LIMITS';
export const REQUEST_LOGIN_LIMITS_SUCCESS = 'REQUEST_LOGIN_LIMITS_SUCCESS';
export const REQUEST_LOGIN_LIMITS_ERROR = 'REQUEST_LOGIN_LIMITS_ERROR';
export const REQUEST_LOGIN_LIMITS_TOTAL_COUNT = 'REQUEST_LOGIN_LIMITS_TOTAL_COUNT';
export const LOGIN_LIMITS_RESET = 'LOGIN_LIMITS_RESET';

export const LOGIN_LIMITS_SEARCH_CHANGE = 'LOGIN_LIMITS_SEARCH_CHANGE';
export const LOGIN_LIMITS_PAGINATION_CHANGE = 'LOGIN_LIMITS_PAGINATION_CHANGE';
export const LOGIN_LIMITS_ITEMS_PER_PAGE_CHANGE = 'LOGIN_LIMITS_ITEMS_PER_PAGE_CHANGE';
export const LOGIN_LIMITS_SORT_CHANGE = 'LOGIN_LIMITS_SORT_CHANGE';
