import { getCollectionReducer } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';
import { getDealProfileFieldsOptions } from '@/condor/application/actions/finsight/deal/helpers';

const newState = {
  isInitialized: false,
  options: {
    products: [],
    regions: [],
    sectors: [],
  },
  sortBy: 'pricingDate',
  sortOrder: 'desc',
  filters: {
    products: [],
  },
};

export default getCollectionReducer(
  actionTypes,
  newState,
  {
    [actionTypes.GET_CONDOR_DEALS_LIST_OPTIONS_SUCCESS]: (state, action) => {
      const [
        products,
        regions,
        sectors,
      ] = action.payload;

      return {
        ...state,
        options: getDealProfileFieldsOptions(
          {
            products,
            regions,
            sectors,
          },
        ),
      };
    },
    [actionTypes.SET_CONDOR_DEALS_LIST_FILTERS_PRODUCTS]: (state, action) => {
      return {
        ...state,
        filters: { ...state.filters, products: [...action.payload] },
      };
    },
  },
);
