import * as actionTypes from '../actionTypes';

const initialState = {
  options: [],
};

export default function slideTypes(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_SLIDE_TYPE:
      return {
        ...state,
        options: Object.keys(action.payload).map((type) => ({
          value: type,
          label: action.payload[type],
        })),
      };
    default:
      return state;
  }
}
