import actionTypes from './actionTypes';
import { getCollectionReducer } from '@/Framework/State/Redux/collectionFactory';

export default getCollectionReducer(
  actionTypes,
  {},
  {
    [actionTypes.GET_COLLECTION_SUCCESS]: (state, action) => (
      {
        ...state,
        isFetching: false,
        totalCount: action.payload.length,
        data: action.payload,
      }
    ),
  },
);
