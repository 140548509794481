import { Component } from 'react';
import { JSONTree } from 'react-json-tree';
import { Modal } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import hInteraction from '@/ui/shared/styles/helpers/interaction.scss';

interface IProps {
  onClose: () => void,
  showItem: { [key: string]: any },
  onSelect: (option: { [key: string]: any }) => void,
  onSelectNotSupport: () => void,
}

class PreviewJsonCellModal extends Component<IProps> {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.getItem = this.getItem.bind(this);
    this.isVisible = this.isVisible.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSelectNotSupport = this.onSelectNotSupport.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  onSelectNotSupport() {
    this.props.onSelectNotSupport();
  }

  onSelect(key, value) {
    this.props.onSelect({
      root: this.getRoot(),
      key,
      value,
    });
    this.onClose();
  }

  getItem() {
    return this.props.showItem.data;
  }

  getRoot() {
    return this.props.showItem.root;
  }

  getTitle() {
    return this.props.showItem.title;
  }

  isVisible() {
    return !!this.props.showItem;
  }

  handleOnClick(path) {
    let detectArray = path.filter((path) => typeof path === 'number');
    if (detectArray.length > 0) {
      this.onSelectNotSupport();
    } else {
      let value = path.splice(0, 1)[0];
      let key = path.reverse().filter((path) => typeof path !== 'number').join('.');
      this.onSelect(key, value);
    }
  }

  /**
   * @return {ReactElement}
   */
  render() {
    if (!this.isVisible()) {
      return null;
    }
    return (
      <div className="json-field-modal">
        <Modal
          onCloseClicked={ () => this.onClose() }
          isVisible
          title={ this.getTitle() }
          dataTest="previewJsonCellModal"
          footer={ (
            <Button
              variant={ variantTypes.secondary }
              onClick={ () => this.onClose() }
              title="Close"
              dataTest="closeButton"
            />
          ) }
        >
          <JSONTree
            data={ { [this.getRoot()]: this.getItem() } }
            shouldExpandNodeInitially={ (keyName, data, level) => level === 1 }
            hideRoot
            invertTheme={ false }
            valueRenderer={ (raw: React.ReactNode, ...rest) => {
              let ref = null;
              let showCursor = () => {
                ref.classList.toggle(hInteraction.clickable);
              };
              return (
                <em
                  ref={ (node) => { ref = node; } }
                  onClick={ () => this.handleOnClick(rest.slice(0)) }
                  onMouseEnter={ showCursor }
                  onMouseLeave={ showCursor }
                >{ raw }
                </em>
              );
            } }
          />
        </Modal>
      </div>
    );
  }
}

export default PreviewJsonCellModal;
