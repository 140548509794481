import * as actionTypes from './actionTypes';

const initialState = {
  isDRS: false,
  isDVDR: false,
  is17G5: false,
  isActivated: true,
  isUnactivated: true,
  isVerified: true,
  isUnverified: true,
  isBanned: true,
  isUnbanned: true,
  search: '',
};

export default function contactsTableFilters(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CONTACTS_TABLE_FILTER_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.RESET_CONTACTS_TABLE_FILTERS:
      return initialState;
    default:
      return state;
  }
}
