import { combineReducers } from 'redux';
import collection from './collection/reducer';
import item from './item/reducer';

export default combineReducers(
  {
    collection,
    item,
  },
);
