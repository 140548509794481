import * as actionTypes from './actionTypes';

const initialState = {
  isDeleteBondModalVisible: false,
  deleteItem: {},
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CONDOR_FINSIGHT_IMPORT_BOND_DELETE_ITEM:
      return {
        ...state,
        deleteItem: action.payload,
      };
    case actionTypes.CHANGE_CONDOR_FINSIGHT_IMPORT_BOND_DELETE_MODAL_VISIBLE:
      return {
        ...state,
        isDeleteBondModalVisible: action.payload,
      };
    default:
      return state;
  }
}
