import { combineReducers } from 'redux';
import collection from './collection/reducer';
import filters from './filters/reducer';
import item from './item/reducer';
import mappings from './mappings/reducer';

export default combineReducers({
  collection,
  item,
  filters,
  mappings,
});
