export const DELETE_CONDOR_FINSIGHT_IMPORT_BOND_BWIC = 'DELETE_CONDOR_FINSIGHT_IMPORT_BOND_BWIC';
export const DELETE_CONDOR_FINSIGHT_IMPORT_BOND_BWIC_SUCCESS = 'DELETE_CONDOR_FINSIGHT_IMPORT_BOND_BWIC_SUCCESS';
export const DELETE_CONDOR_FINSIGHT_IMPORT_BOND_BWIC_ERROR = 'DELETE_CONDOR_FINSIGHT_IMPORT_BOND_BWIC_ERROR';

export const DELETE_CONDOR_FINSIGHT_IMPORT_BOND_INVENTORY = 'DELETE_CONDOR_FINSIGHT_IMPORT_BOND_INVENTORY';
export const DELETE_CONDOR_FINSIGHT_IMPORT_BOND_INVENTORY_SUCCESS = 'DELETE_CONDOR_FINSIGHT_IMPORT_BOND_INVENTORY_SUCCESS';
export const DELETE_CONDOR_FINSIGHT_IMPORT_BOND_INVENTORY_ERROR = 'DELETE_CONDOR_FINSIGHT_IMPORT_BOND_INVENTORY_ERROR';

export const SET_CONDOR_FINSIGHT_IMPORT_BOND_DELETE_ITEM = 'SET_CONDOR_FINSIGHT_IMPORT_BOND_DELETE_ITEM';
export const CHANGE_CONDOR_FINSIGHT_IMPORT_BOND_DELETE_MODAL_VISIBLE = 'CHANGE_CONDOR_FINSIGHT_IMPORT_BOND_DELETE_MODAL_VISIBLE';
