import { formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  resetState,
  changeFilter,
  reloadSalt,
} from '@/condor/application/actions/databases/queryFilterForm/actions';
import QueryFilterForm from './QueryFilterForm';

const selector = formValueSelector('queryFilterForm');

function mapStateToProps(state) {
  return {
    forms: selector(state, 'forms'),
    activeForm: selector(state, 'activeForm'),
    initialValues: { forms: [], activeForm: null },
    logicOperators: state.technicalSupport.databases.queryFilterForm.logicOperators,
    conditionalOperators: state.technicalSupport.databases.queryFilterForm.conditionalOperators,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    resetState,
    changeFilter,
    reloadSalt,
    onSubmit: () => {},
  }, dispatch);
}

const mergeProps = (state, actions) => ({
  ...state,
  ...actions,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  { forwardRef: true },
)(QueryFilterForm);
