import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  databases: null,
  path: [],
  pathData: null,
  drs: null,
  finsight_web: null,
  finsight_strats: null,
  external: null,
  condor: null,
};

export default function databasesStructure(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DATABASES_STRUCTURE_RESET:
      return initialState;
    case actionTypes.REQUEST_DATABASES_STRUCTURE:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_DATABASES_STRUCTURE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        databases: {
          ...action.payload.drs,
          mysql: {
            ...action.payload.drs.mysql,
            ...action.payload.finsight_web.mysql,
            ...action.payload.finsight_strats.mysql,
            ...action.payload.external.mysql,
            ...action.payload.condor.mysql,
          },
          postgres: {
            ...action.payload.drs.postgres,
            ...action.payload.finsight_web.postgres,
            ...action.payload.finsight_strats.postgres,
            ...action.payload.external.postgres,
            ...action.payload.condor.postgres,
          },
        },
        drs: action.payload.drs,
        finsight_web: action.payload.finsight_web,
        finsight_strats: action.payload.finsight_strats,
        external: action.payload.external,
        condor: action.payload.condor,
      };
    case actionTypes.REQUEST_DATABASES_STRUCTURE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.DATABASES_STRUCTURE_CHANGE_PATH:
      return {
        ...state,
        path: action.payload,
      };
    case actionTypes.DATABASES_STRUCTURE_CHANGE_PATH_DATA:
      return {
        ...state,
        pathData: action.payload,
      };
    default:
      return state;
  }
}
