import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  list: [],
  lastUpdateTime: null,
  maxId: 0,
};

export default function eventLogOffset(state = initialState, action) {
  switch (action.type) {
    case actionTypes.EVENT_LOG_OFFSET_RESET:
      return initialState;
    case actionTypes.REQUEST_EVENT_LOG_OFFSET:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_EVENT_LOG_OFFSET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case actionTypes.EVENT_LOG_OFFSET_LAST_UPDATE_TIME:
      return {
        ...state,
        lastUpdateTime: action.payload,
      };
    case actionTypes.REQUEST_EVENT_LOG_MAX_ID_SUCCESS:
      return {
        ...state,
        maxId: action.payload,
      };
    case actionTypes.REQUEST_EVENT_LOG_OFFSET_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
