import { getItemActionTypes } from '@/Framework/State/Redux/itemFactory';

export default {
  ...getItemActionTypes(
    'CONDOR_FINSIGHT_COMPANY',
    { withCreate: true, withUpdate: true, withRemove: true },
  ),
  SET_CONDOR_FINSIGHT_COMPANY_IS_INLINE_EDITING: 'SET_CONDOR_FINSIGHT_COMPANY_IS_INLINE_EDITING',
  SET_CONDOR_FINSIGHT_COMPANY_IS_DELETE_MODAL_VISIBLE: 'SET_CONDOR_FINSIGHT_COMPANY_IS_DELETE_MODAL_VISIBLE',
  SET_CONDOR_FINSIGHT_COMPANY_IS_MIGRATE_MODAL_VISIBLE: 'SET_CONDOR_FINSIGHT_COMPANY_IS_MIGRATE_MODAL_VISIBLE',
  GET_CONDOR_FINSIGHT_COMPANY_DEALS: 'GET_CONDOR_FINSIGHT_COMPANY_DEALS',
  GET_CONDOR_FINSIGHT_COMPANY_DEALS_SUCCESS: 'GET_CONDOR_FINSIGHT_COMPANY_DEALS_SUCCESS',
  GET_CONDOR_FINSIGHT_COMPANY_DEALS_ERROR: 'GET_CONDOR_FINSIGHT_COMPANY_DEALS_ERROR',
  MIGRATE_CONDOR_FINSIGHT_COMPANY_DEALS: 'MIGRATE_CONDOR_FINSIGHT_COMPANY_DEALS',
  MIGRATE_CONDOR_FINSIGHT_COMPANY_DEALS_SUCCESS: 'MIGRATE_CONDOR_FINSIGHT_COMPANY_DEALS_SUCCESS',
  MIGRATE_CONDOR_FINSIGHT_COMPANY_DEALS_ERROR: 'MIGRATE_CONDOR_FINSIGHT_COMPANY_DEALS_ERROR',
  GET_COMPANY_INFORMATION_FOR_MIGRATION: 'GET_COMPANY_INFORMATION_FOR_MIGRATION',
  GET_COMPANY_INFORMATION_FOR_MIGRATION_SUCCESS: 'GET_COMPANY_INFORMATION_FOR_MIGRATION_SUCCESS',
  GET_COMPANY_INFORMATION_FOR_MIGRATION_ERROR: 'GET_COMPANY_INFORMATION_FOR_MIGRATION_ERROR',
  CREATE_CONDOR_FINSIGHT_COMPANY_ITEM_MODAL: 'CREATE_CONDOR_FINSIGHT_COMPANY_ITEM_MODAL',
  CREATE_CONDOR_FINSIGHT_COMPANY_ITEM_MODAL_SUCCESS: 'CREATE_CONDOR_FINSIGHT_COMPANY_ITEM_MODAL_SUCCESS',
  CREATE_CONDOR_FINSIGHT_COMPANY_ITEM_MODAL_ERROR: 'CREATE_CONDOR_FINSIGHT_COMPANY_ITEM_MODAL_ERROR',
};
