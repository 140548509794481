import * as actionTypes from '../actionTypes';

const initialState = {
  initialValues: {},
  isFetching: false,
  isRefreshModalVisible: false,
};

export default function roadshowMapping(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ROADSHOW_MAPPING_RESET:
      return initialState;
    case actionTypes.REQUEST_ROADSHOW_MAPPING:
      return {
        ...state,
        initialValues: {},
        isFetching: true,
      };
    case actionTypes.REQUEST_ROADSHOW_MAPPING_SUCCESS:
      let { sector, industry } = action.payload.initialValues;
      return {
        initialValues: {
          ...(sector && sector.subsector ? { subsectorId: sector.subsector.subsectorId } : {}),
          ...(industry && industry.subindustry ? { subindustryId: industry.subindustry.subindustryId } : {}),
        },
        isFetching: false,
      };
    case actionTypes.REQUEST_ROADSHOW_MAPPING_ERROR:
    case actionTypes.REQUEST_ROADSHOW_MAPPING_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.REQUEST_ROADSHOW_MAPPING_UPDATE:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.SET_DEAL_MAPPING_NEED_TO_REFRESH_MODAL_VISIBLE:
      return {
        ...state,
        isRefreshModalVisible: action.payload,
      };
    default:
      return state;
  }
}
