import { combineReducers } from 'redux';
import upload from './upload/reducer';
import validation from './validation/reducer';
import auditTrail from './auditTrail/reducer';

export default combineReducers(
  {
    upload,
    validation,
    auditTrail,
  },
);
