import { getCollectionActionTypes } from '@/Framework/State/Redux/collectionFactory';

export default {
  ...getCollectionActionTypes(
    'NEW_CONDOR_FINSIGHT_SUBSCRIBERS',
    {
      withSort: true,
      withSearch: true,
    },
  ),
};
