import * as actionTypes from '../actionTypes';

const initialState = {
  isFetching: false,
  data: {},
  initialValues: {},
  itemSettings: {
    timeZone: {
      id: '',
      timeZone: 'America/New_York',
      abbr: 'ET',
    },
  },
};

export default function roadshow(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_ROADSHOW:
      return {
        ...state,
        data: {},
        initialValues: {},
        isFetching: true,
      };
    case actionTypes.REQUEST_ROADSHOW_SUCCESS:
      return {
        isFetching: false,
        data: action.payload.data,
        initialValues: action.payload.initialValues,
      };
    case actionTypes.REQUEST_ROADSHOW_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.REQUEST_ROADSHOW_SETTINGS_SUCCESS:
      return {
        ...state,
        itemSettings: action.payload,
      };
    default:
      return state;
  }
}
