import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  collection: [],
};

export default function cdnList(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_CDN_LIST:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_CDN_LIST_SUCCESS:
      return {
        ...state,
        collection: action.payload,
        isFetching: false,
      };
    case actionTypes.REQUEST_CDN_LIST_ERROR:
      return {
        ...state,
        collection: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
}
