import actionTypes from './actionTypes';
import { getItemReducer } from '@/Framework/State/Redux/itemFactory';

const newState = {
  isInlineEditing: false,
  isDeleteModalVisible: false,
};

export default getItemReducer(
  actionTypes,
  newState,
  {
    [actionTypes.RESTORE_CONDOR_ACCOUNT]: (state) => (
      { ...state, isUpdating: true }
    ),
    [actionTypes.RESTORE_CONDOR_ACCOUNT_SUCCESS]: (state) => (
      { ...state, isUpdating: false }
    ),
    [actionTypes.RESTORE_CONDOR_ACCOUNT_ERROR]: (state) => (
      { ...state, isUpdating: false }
    ),
    [actionTypes.SET_CONDOR_ACCOUNTS_IS_INLINE_EDITING]: (state, action) => (
      { ...state, isInlineEditing: action.payload }
    ),
    [actionTypes.SET_CONDOR_ACCOUNTS_IS_DELETE_MODAL_VISIBLE]: (state, action) => (
      { ...state, isDeleteModalVisible: action.payload }
    ),
  },
);
