import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changePath } from '@/condor/application/actions/databases/structure/actions';
import { setBreadcrumb, removeBreadcrumb } from '@/condor/ui/common/Breadcrumb/actions';
import Databases from '../components/Databases';

function mapStateToProps(state, ownProps) {
  state.match = ownProps.match;
  return {
    match: state.match,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setBreadcrumb,
    removeBreadcrumb,
    initStructurePath: changePath,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Databases);
