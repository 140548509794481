import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  collection: [],
  totalCount: 0,
  page: 1,
  perPage: 25,
  sortBy: null,
  sortOrder: 'desc',
};

export default function current(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TABLE_DATA_RESET:
      return initialState;
    case actionTypes.REQUEST_TABLE_DATA:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_TABLE_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        collection: action.payload,
      };

    case actionTypes.REQUEST_TABLE_DATA_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case actionTypes.REQUEST_TABLE_DATA_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.TABLE_DATA_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case actionTypes.TABLE_DATA_ITEMS_PER_PAGE_CHANGE:
      return {
        ...state,
        perPage: action.payload.perPage,
      };
    case actionTypes.TABLE_DATA_SORT_CHANGE:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    default:
      return state;
  }
}
