import { TRANCHE_GROUP_IDS } from './trancheGroupIds';

export default {
  ID: {
    name: 'id',
    isHidden: true, // isHidden means that this field won't be rendered in tranche properties column,
    groupId: TRANCHE_GROUP_IDS.CLASS,
  },
  IS_EXCLUDED: {
    name: 'isExlcuded', // This field is inserted into tranche object on frontend side.
    isBoolean: true,
    isHidden: true,
    groupId: TRANCHE_GROUP_IDS.CLASS,
  },
  ORDERING_POSITION: {
    name: 'orderingPosition',
    label: 'Ordering Position', // Ordering Position is displayed in changes list
    isHidden: true,
    groupId: TRANCHE_GROUP_IDS.CLASS,
  },
  CLASS: {
    name: 'class',
    label: 'Class',
    groupId: TRANCHE_GROUP_IDS.CLASS,
  },
  CURRENCY: {
    name: 'currencyId',
    label: 'Currency',
    isSelect: true,
    groupId: TRANCHE_GROUP_IDS.CLASS,
  },
  SIZE: {
    name: 'size',
    label: 'Size (M)',
    isNumber: true,
    groupId: TRANCHE_GROUP_IDS.CLASS,
  },
  WAL_YRS: {
    name: 'weightedAverageLife',
    label: 'WAL (Yrs)',
    labelTooltipContent: 'Weighted Average Life (years)',
    isNumber: true,
    groupId: TRANCHE_GROUP_IDS.CLASS,
  },
  WAL_EXT: {
    name: 'weightedAverageLife2',
    label: 'WAL ext',
    labelTooltipContent: 'Weighted Average Life (extended)',
    isNumber: true,
    groupId: TRANCHE_GROUP_IDS.CLASS,
  },
  RANKING: {
    name: 'rankingId',
    label: 'Ranking',
    isSelect: true,
    groupId: TRANCHE_GROUP_IDS.CLASS,
  },
  TENOR_YRS: {
    name: 'tenorYrs',
    label: 'Tenor',
    isSeparator: true, // isSeparator means that the separator line below this field has to be drawn
    isNumber: true,
    groupId: TRANCHE_GROUP_IDS.CLASS,
  },
  RATINGS: {
    name: 'ratings',
    groupId: TRANCHE_GROUP_IDS.RATING_AGENCIES,
  },
  CREDIT_ENHANCEMENT: {
    name: 'creditEnhancement',
    label: 'C/E',
    labelTooltipContent: 'Credit Enhancement',
    isNumber: true,
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  LOAN_TO_VALUE: {
    name: 'loanToValue',
    label: 'LTV',
    labelTooltipContent: 'Loan-to-Value',
    isNumber: true,
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  EXP_MATURITY_DATE: {
    name: 'expectedMaturityDate',
    label: 'Exp Final',
    labelTooltipContent: 'Expected Final',
    isDate: true,
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  MATURITY_DATE: {
    name: 'maturityDate',
    label: 'Maturity',
    isDate: true,
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  FIRST_PAY_DATE: {
    name: 'firstPayDate',
    label: 'First Pay Date',
    isDate: true,
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  REDEMPTION: {
    name: 'redemption',
    label: 'Redemption',
    isDate: true,
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  COUPON_TYPE: {
    name: 'couponTypeId',
    labelTooltipContent: 'Coupon Type',
    label: 'Coupon Type',
    isSelect: true,
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  BENCHMARK: {
    name: 'benchmarkId',
    label: 'Benchmark',
    isSelect: true,
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  GUIDANCE_IPT: {
    name: 'guidanceIpt',
    label: 'Init. Price Talk',
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  GUIDANCE: {
    name: 'guidance',
    label: 'Guidance',
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  SPREAD: {
    name: 'spread',
    label: 'Spread (bps)',
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  COUPON: {
    name: 'coupon',
    label: 'Coupon',
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  YIELD: {
    name: 'yield',
    label: 'Yield',
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  ISSUE_PRICE: {
    name: 'issuePrice',
    label: 'Price',
    isNumber: true,
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  NEW_ISSUE_CONCESSION: {
    name: 'newIssueConcession',
    label: 'NIC (bps)',
    labelTooltipContent: 'New Issue Concessions',
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  BOOK_SIZE: {
    name: 'bookSize',
    label: 'Book Size (M)',
    isNumber: true,
    isSeparator: true, // isSeparator means that the separator line below this field has to be drawn
    groupId: TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
  },
  CUSIPS: {
    name: 'cusips',
    label: 'CUSIP',
    groupId: TRANCHE_GROUP_IDS.CUSIP,
  },
  CUSIPS_REGS: {
    name: 'cusipsRegs',
    label: 'RegS CUSIP',
    groupId: TRANCHE_GROUP_IDS.CUSIP,
  },
  ISINS: {
    name: 'isins',
    label: 'ISIN',
    groupId: TRANCHE_GROUP_IDS.CUSIP,
  },
  REGISTRATION: {
    name: 'registrationId',
    label: 'Registration',
    isSelect: true,
    groupId: TRANCHE_GROUP_IDS.CUSIP,
  },
  ERISA_ELIGABLE: {
    name: 'erisaEligible',
    label: 'ERISA',
    isBoolean: true,
    groupId: TRANCHE_GROUP_IDS.CUSIP,
  },
  RETAINED: {
    name: 'retained',
    label: 'Retained',
    isBoolean: true,
    groupId: TRANCHE_GROUP_IDS.CUSIP,
  },
  // interestsOnly is visible when the selected ABS Sector is CMBS
  INTEREST_ONLY: {
    name: 'interestOnly',
    label: 'I/O',
    labelTooltipContent: 'Interest Only',
    isBoolean: true,
    groupId: TRANCHE_GROUP_IDS.CUSIP,
  },
  UPSIZED: {
    name: 'upsized',
    label: 'Upsized',
    isBoolean: true,
    groupId: TRANCHE_GROUP_IDS.CUSIP,
  },
  RE_OPEN: {
    name: 'reOpen',
    label: 'Re-Open / Tap',
    isBoolean: true,
    isSeparator: true,
    groupId: TRANCHE_GROUP_IDS.CUSIP,
  },
  COMMENTS: {
    name: 'comments',
    label: 'Comments',
    groupId: TRANCHE_GROUP_IDS.COMMENTS,
  },
};
