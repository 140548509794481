import { getItemActionTypes } from '@/Framework/State/Redux/itemFactory';

export default {
  ...getItemActionTypes(
    'CONDOR_FINSIGHT_FILING',
  ),

  MAP_FILING_TO_DEAL: 'MAP_FILING_TO_DEAL',
  MAP_FILING_TO_DEAL_SUCCESS: 'MAP_FILING_TO_DEAL_SUCCESS',
  MAP_FILING_TO_DEAL_ERROR: 'MAP_FILING_TO_DEAL_ERROR',

  UNMAP_FILING_FROM_DEAL: 'UNMAP_FILING_FROM_DEAL',
  UNMAP_FILING_FROM_DEAL_SUCCESS: 'UNMAP_FILING_FROM_DEAL_SUCCESS',
  UNMAP_FILING_FROM_DEAL_ERROR: 'UNMAP_FILING_FROM_DEAL_ERROR',

  GET_CONDOR_EXISTING_DEAL_OPTIONS: 'GET_CONDOR_EXISTING_DEAL_OPTIONS',
  GET_CONDOR_EXISTING_DEAL_OPTIONS_SUCCESS: 'GET_CONDOR_EXISTING_DEAL_OPTIONS_SUCCESS',
  GET_CONDOR_EXISTING_DEAL_OPTIONS_ERROR: 'GET_CONDOR_EXISTING_DEAL_OPTIONS_ERROR',

  GET_CONDOR_EXISTING_DEAL: 'GET_CONDOR_EXISTING_DEAL',
  GET_CONDOR_EXISTING_DEAL_SUCCESS: 'GET_CONDOR_EXISTING_DEAL_SUCCESS',
  GET_CONDOR_EXISTING_DEAL_ERROR: 'GET_CONDOR_EXISTING_DEAL_ERROR',

  RESET_CONDOR_EXISTING_DEAL_FORM: 'RESET_CONDOR_EXISTING_DEAL_FORM',
};
