import { getCollectionReducer } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';

export default getCollectionReducer(
  actionTypes,
  {
    sortBy: 'createdAt',
    sortOrder: 'desc',
  },
);
