import { combineReducers } from 'redux';
import { validateDomain, existInList } from '@/dealroadshow/application/actions/blacklist/validation';
import createAction from '@/Framework/State/Redux/createAction';
import { getItemActionTypes, getItemActions, getItemReducer, getItemSelectors } from '@/Framework/State/Redux/itemFactory';
import {
  getCollectionActionTypes,
  getCollectionActions,
  getCollectionReducer,
  getCollectionSelectors,
} from '@/Framework/State/Redux/collectionFactory';

// ACTION TYPES

/**
 * @param {String} key
 */
export const getBlacklistActionTypes = (key) => (
  {
    ...getItemActionTypes(key, { withGet: false, withRemove: true }),
    ...getCollectionActionTypes(key, { withSort: true, withSearch: true }),

    ADD_ITEMS: `ADD_${ key }_ITEMS`,
    ADD_ITEMS_SUCCESS: `ADD_${ key }_ITEMS_SUCCESS`,
    ADD_ITEMS_ERROR: `ADD_${ key }_ITEMS_ERROR`,

    SET_IS_DELETE_MODAL_VISIBLE: `SET_${ key }_IS_DELETE_MODAL_VISIBLE`,
    SET_ADD_ITEMS_INPUT_STATE: `SET_${ key }_ADD_ITEMS_INPUT_STATE`,
    RESET_ADD_ITEMS_INPUT_STATE: `RESET_${ key }_ADD_ITEMS_INPUT_STATE`,
  }
);

// ACTIONS

/**
 * @param {Object} actionTypes
 * @param {Function} fetchCollectionMethod
 * @param {Object} removeItemConfig
 */
export const getBlacklistActions = (actionTypes, fetchCollectionMethod, removeItemConfig) => {
  const {
    reset: resetItem,
    ...restItemActions
  } = getItemActions(actionTypes, { remove: removeItemConfig });

  const {
    reset: resetCollection,
    ...restCollectionActions
  } = getCollectionActions(actionTypes, fetchCollectionMethod);

  return {
    ...restItemActions,
    ...restCollectionActions,
    resetItem,
    resetCollection,

    setIsDeleteModalVisible: (payload) => createAction(actionTypes.SET_IS_DELETE_MODAL_VISIBLE, payload),
    setAddItemsInputState: (payload) => createAction(actionTypes.SET_ADD_ITEMS_INPUT_STATE, payload),
    resetAddItemsInputState: () => createAction(actionTypes.RESET_ADD_ITEMS_INPUT_STATE),
  };
};

// REDUCERS

const addItemsInputReducerInitialState = {
  value: '',
  isAdding: false,
  isFocused: false,
  errors: [],
};

/**
 * @param actionTypes
 */
const getAddItemsInputReducer = (actionTypes) => (state = addItemsInputReducerInitialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ADD_ITEMS_INPUT_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.RESET_ADD_ITEMS_INPUT_STATE:
      return addItemsInputReducerInitialState;
    default:
      return state;
  }
};

const newItemState = {
  isDeleteModalVisible: false,
};

const newCollectionState = {
  sortBy: 'createdAt',
  sortOrder: 'desc',
};

/**
 * @param actionTypes
 * @param collectionAdditionalCases
 */
export const getBlacklistReducer = (actionTypes, collectionAdditionalCases) => combineReducers(
  {
    addItemsInput: getAddItemsInputReducer(actionTypes),
    collection: getCollectionReducer(actionTypes, newCollectionState, collectionAdditionalCases),
    item: getItemReducer(
      actionTypes,
      newItemState,
      {
        [actionTypes.SET_IS_DELETE_MODAL_VISIBLE]: (state, action) => (
          {
            ...state,
            isDeleteModalVisible: action.payload,
          }
        ),
      },
    ),
  },
);

// SELECTORS

/**
 *
 * @param {String} path
 * @param {String} field
 * @returns {function(*): *}
 */
const createAddItemInputSelector = (path, field) => (state) => state.condor.blacklists[path].addItemsInput[field];

/**
 * @param {String} path
 * @param {Object} actionTypes
 */
export const getBlacklistSelectors = (path, actionTypes) => (
  {
    ...getItemSelectors(`condor.blacklists.${ path }.item`, actionTypes),
    isDeleteModalVisible: (state) => state.condor.blacklists[path].item.isDeleteModalVisible,

    ...getCollectionSelectors(`condor.blacklists.${ path }.collection`, actionTypes),

    getAddInputValue: createAddItemInputSelector(path, 'value'),
    isAdding: createAddItemInputSelector(path, 'isAdding'),
    isAddInputFocused: createAddItemInputSelector(path, 'isFocused'),
    getAddingErrors: createAddItemInputSelector(path, 'errors'),
  }
);

// VALIDATION

/**
 * @param {String} inputValue
 * @param {Array} initialCollection
 */
export const extractDomainsFromStringWithValidation = (inputValue, initialCollection = []) => {
  const errors = [];
  const domains = inputValue
    .split(/[ ,;]+/)
    .map((valueItem) => valueItem.trim())
    .filter((valueItem) => !!valueItem)
    .filter((item, pos, array) => array.indexOf(item) === pos)
    .map((value) => {
      const isInvalid = validateDomain(value);
      const isInList = existInList(initialCollection, inputValue);

      if (isInvalid) {
        errors.push(isInvalid);
      }

      if (isInList) {
        errors.push(isInList);
      }

      return { domain: value };
    });

  if (!errors.length && domains.length > 1000) {
    errors.push('Maximum number of domains or email addresses has been exceeded');
  }

  return {
    items: domains,
    errors,
  };
};
