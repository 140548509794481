import * as actionTypes from '../actionTypes';

const initialState = {
  initialValues: {},
  data: {},
  isFetching: false,
  isRefreshModalVisible: false,
  hasEvercallError: false,
};

export default function dealSettings(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DEAL_SETTINGS_RESET:
      return initialState;
    case actionTypes.REQUEST_DEAL_SETTINGS:
      return {
        ...state,
        initialValues: {},
        data: {},
        isFetching: true,
      };
    case actionTypes.REQUEST_EVERCALL_DEAL_SETTINGS_SUCCESS:
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          viewerCustomizationEnabled: true,
          disclaimerBackgroundImage: null,
          disclaimerLogo: null,
          roadshowViewerCustomizeEnabled: true,
          ...action.payload,
        },
        isFetching: false,
      };
    case actionTypes.REQUEST_DEAL_SETTINGS_SUCCESS:
      const singleEntryCodeCode = action.payload.initialValues.singleEntryCode
                                  && action.payload.initialValues.singleEntryCode.code;
      const isLoginsMaxNumber = !!action.payload.initialValues.loginsMaxNumber;
      const cdnTuple = action.payload.initialValues.cdnTuple.key;
      return {
        initialValues: {
          ...action.payload.initialValues,
          singleEntryCodeCode,
          isLoginsMaxNumber,
          cdnTuple,
        },
        data: action.payload.data,
        isFetching: false,
      };
    case actionTypes.REQUEST_DEAL_SETTINGS_ERROR:
    case actionTypes.REQUEST_DEAL_SETTINGS_UPDATE_SUCCESS:
    case actionTypes.REQUEST_DEAL_SETTINGS_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.REQUEST_DEAL_SETTINGS_UPDATE:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.SET_DEAL_SETTINGS_NEED_TO_REFRESH_MODAL_VISIBLE:
      return {
        ...state,
        isRefreshModalVisible: action.payload,
      };
    case actionTypes.SET_EVERCALL_CONFERENCE_ERROR:
      return {
        ...state,
        isFetching: false,
        hasEvercallError: action.payload,
      };
    default:
      return state;
  }
}
