import { getCollectionReducer } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';

const newState = {
  sortBy: 'fullName',
  sortOrder: 'asc',
  perPage: 10,
};

export default getCollectionReducer(actionTypes, newState, {
  [actionTypes.GET_COLLECTION_SUCCESS]: (state, action) => (
    {
      ...state,
      data: action.payload.collection,
      notesTotalCount: action.payload.notesTotalCount,
      totalCount: action.payload.totalCount,
      isFetching: false,
    }
  ),
});
