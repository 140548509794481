import config from '@/Framework/config';
import buildUrl from '@/Framework/url/helpers/buildUrl';
import shouldUseInternalNetwork from '@/Framework/Router/Next/shouldUseInternalNetwork';

export default {
  getUrl(...uris: (string | string[])[]): string {
    return buildUrl(config.protocol, config.tenant.finsight.hostname, ...uris);
  },

  getApiUrl(uris: string | string[] = []): string {
    return this.getUrl('/strats/api', uris);
  },

  getRpcUrl(): string {
    if (shouldUseInternalNetwork()) {
      return this.getInternalRpcUrl();
    }

    return `${ config.tenant.finsight.api.external }/strats/rpc`;
  },

  getInternalRpcUrl(): string {
    return `${ config.tenant.finsight.api.internal.finsightStrats }/rpc`;
  },
};
