/**
 * @param {String} id
 * @param {String} name
 * @param {String|null} issuerTicker
 * @return {Object}
 */
export const createCompanyOptionWithIssuerTicker = (
  {
    id,
    name,
    issuerTicker,
  },
) => (
  {
    value: id,
    label: `${ name }${ issuerTicker ? ` (${ issuerTicker })` : '' }`,
    issuerTicker,
  }
);

/**
 * @param {String} id
 * @param {String} name
 * @param {String|null} issuerTicker
 * @param {String|null} abbreviation
 * @return {Object}
 */
export const createCompanyOptionWithIssuerTickerAndAbbreviation = (
  {
    id,
    name,
    issuerTicker,
    abbreviation,
  },
) => (
  {
    value: id,
    label: `${ name }${ abbreviation ? ` ${ abbreviation }` : '' }${ issuerTicker ? ` (${ issuerTicker })` : '' }`,
    abbreviation,
    issuerTicker,
    name,
  }
);
