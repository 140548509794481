export const REQUEST_ANALYTICS = 'REQUEST_ANALYTICS';
export const REQUEST_ANALYTICS_SUCCESS = 'REQUEST_ANALYTICS_SUCCESS';
export const REQUEST_ANALYTICS_ERROR = 'REQUEST_ANALYTICS_ERROR';
export const REQUEST_ANALYTICS_TOTAL_COUNT = 'REQUEST_ANALYTICS_TOTAL_COUNT';
export const ANALYTICS_RESET = 'ANALYTICS_RESET';

export const ANALYTICS_TABLE_FILTER_SEARCH = 'ANALYTICS_TABLE_FILTER_SEARCH';
export const ANALYTICS_TABLE_FILTER_PAGINATION = 'ANALYTICS_TABLE_FILTER_PAGINATION';
export const ANALYTICS_TABLE_FILTER_PER_PAGE = 'ANALYTICS_TABLE_FILTER_PER_PAGE';
export const ANALYTICS_TABLE_SORT = 'ANALYTICS_TABLE_SORT';
export const ANALYTICS_TABLE_RESET = 'ANALYTICS_TABLE_RESET';
