import * as actionTypes from '../actionTypes';

const initialState = {
  isFetching: false,
  collection: [],
  totalCount: 0,

  search: '',
  perPage: 50,
  page: 1,
  sortBy: 'totalViews',
  sortOrder: 'desc',
};

export default function loginLimits(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_LIMITS_RESET:
      return initialState;
    case actionTypes.REQUEST_LOGIN_LIMITS:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_LOGIN_LIMITS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        collection: action.payload,
      };
    case actionTypes.REQUEST_LOGIN_LIMITS_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case actionTypes.REQUEST_LOGIN_LIMITS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.LOGIN_LIMITS_SEARCH_CHANGE:
      return {
        ...state,
        search: action.payload.search,
        page: 1,
      };
    case actionTypes.LOGIN_LIMITS_PAGINATION_CHANGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case actionTypes.LOGIN_LIMITS_ITEMS_PER_PAGE_CHANGE:
      return {
        ...state,
        perPage: action.payload.perPage,
        page: 1,
      };
    case actionTypes.LOGIN_LIMITS_SORT_CHANGE:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
        page: 1,
      };
    default:
      return state;
  }
}
