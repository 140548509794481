export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_PAYLOAD = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_PAYLOAD';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_SET_HEADER = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_ADD_HEADER';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_ADD_BODY_ROWS = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_ADD_BODY_ROWS';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_SET_BODY_ROWS = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_SET_BODY_ROWS';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_SET_CORRECTION_DATA = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_SET_CORRECTION_DATA';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_NEED_CORRECTION_FLAG = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_NEED_CORRECTION_FLAG';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_CAN_REIMPORT_CHANGE = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_CAN_REIMPORT_CHANGE';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_TABLE_VISIBLE = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_TABLE_VISIBLE';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_COMPANY_SELECT_VISIBLE = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_COMPANY_SELECT_VISIBLE';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_COMPANY_COLLECTION_CHANGE = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_COMPANY_COLLECTION_CHANGE';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_COMPANY_CREATE_VISIBLE = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_COMPANY_CREATE_VISIBLE';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_COMPANY_CREATE_DATA_INIT = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_COMPANY_CREATE_DATA_INIT';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_ACTIVE_VALIDATE_CELL_CHANGE = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_ACTIVE_VALIDATE_CELL_CHANGE';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_RESET_WITHOUT_CORRECTION_DATA = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_RESET_WITHOUT_CORRECTION_DATA';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_RESET = 'CONDOR_FINSIGHT_IMPORT_COMPANY_VALIDATION_RESET';
