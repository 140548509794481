export default {
  // used for validation
  NUMBER_WITHOUT_DECIMAL: /^$|^\d+$/,
  NUMBER_WITH_REQUIRED_DECIMAL: /^$|^\d+\.\d+$/,
  NUMBER_WITH_OPTIONAL_DECIMAL_12_BEFORE_6_AFTER_POINT: /^$|^\d{1,12}(\.\d{1,6})?$/,
  NUMBER_WITH_OPTIONAL_DECIMAL_4_BEFORE_3_AFTER_POINT: /^$|^\d{1,4}(\.\d{1,3})?$/,
  NUMBER_WITH_OPTIONAL_DECIMAL_3_BEFORE_3_AFTER_POINT: /^$|^\d{1,3}(\.\d{1,3})?$/,
  NUMBER_WITH_OPTIONAL_DECIMAL_3_BEFORE_2_AFTER_POINT: /^$|^\d{1,3}(\.\d{1,2})?$/,
  STRING_CUSIP_CODE: /^$|^[0-9][0-9a-zA-Z]{8}(?:[,|;]\s*[0-9a-zA-Z]{8})*$/,
  STRING_CUSIP_REGS_CODE: /^$|^[A-Z][0-9a-zA-Z]{8}(?:[,|;]\s*[A-Z][0-9a-zA-Z]{8})*$/,
  STRING_ISIN_CODE: /^$|^[0-9a-zA-Z]{12}(?:[,|;]\s*[0-9a-zA-Z]{12})*$/,
  // used for cusips and isins processing
  SYMBOL_COMMA_OR_VERTICAL_BAR_OR_SEMICOLON: /[,|;]/,
  SYMBOL_SPACE: /\s/g,
};
