import { getItemReducer } from '@/Framework/State/Redux/itemFactory';
import actionTypes from './actionTypes';
import { mapToDefaultOption } from '@/Framework/dataHelpers/selectOptionMappers';

const newState = {
  isUnmapping: false,
  isMapping: false,
  existingDeal: {},
  existingDealsOptions: [],
};

export default getItemReducer(
  actionTypes,
  newState, {
    [actionTypes.MAP_FILING_TO_DEAL]: (state) => (
      {
        ...state,
        isMapping: true,
      }
    ),
    [actionTypes.MAP_FILING_TO_DEAL_SUCCESS]: (state) => (
      {
        ...state,
        isMapping: false,
      }
    ),
    [actionTypes.MAP_FILING_TO_DEAL_ERROR]: (state) => (
      {
        ...state,
        isMapping: false,
      }
    ),
    [actionTypes.UNMAP_FILING_FROM_DEAL]: (state) => (
      {
        ...state,
        isUnmapping: true,
      }
    ),
    [actionTypes.UNMAP_FILING_FROM_DEAL_SUCCESS]: (state) => (
      {
        ...state,
        isUnmapping: false,
      }
    ),
    [actionTypes.UNMAP_FILING_FROM_DEAL_ERROR]: (state) => (
      {
        ...state,
        isUnmapping: false,
      }
    ),
    [actionTypes.GET_CONDOR_EXISTING_DEAL]: (state) => (
      {
        ...state,
        isFetching: true,
      }
    ),
    [actionTypes.GET_CONDOR_EXISTING_DEAL_SUCCESS]: (state, action) => (
      {
        ...state,
        isFetching: false,
        existingDeal: action.payload,
      }
    ),
    [actionTypes.GET_CONDOR_EXISTING_DEAL_ERROR]: (state) => (
      {
        ...state,
        isFetching: false,
      }
    ),
    [actionTypes.GET_CONDOR_EXISTING_DEAL_OPTIONS_SUCCESS]: (state, action) => (
      {
        ...state,
        existingDealsOptions: action.payload.collection.map(mapToDefaultOption),
      }
    ),
    [actionTypes.RESET_CONDOR_EXISTING_DEAL_FORM]: (state) => (
      {
        ...state,
        existingDeal: {},
        existingDealsOptions: [],
      }
    ),
  },
);
