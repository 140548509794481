import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import FinsightWebRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import FinsightStratsRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-strats/HttpDispatcher';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import ExternalRpcDispatcher from '@/Framework/DI/Providers/Rpc/externalApi/HttpDispatcher';
import CondorRpcDispatcher from '@/condor/application/DI/Rpc/HttpDispatcher';

@Dependencies(
  RpcDispatcher,
  FinsightWebRpcDispatcher,
  FinsightStratsRpcDispatcher,
  ExternalRpcDispatcher,
  CondorRpcDispatcher,
)
class DatabasesRepository {
  constructor(
    rpcDispatcher,
    finsightWebRpcDispatcher,
    finsightStratsRpcDispatcher,
    externalRpcDispatcher,
    condorRpcDispatcher,
  ) {
    this.rpc = rpcDispatcher;
    this.finsightWebRpc = finsightWebRpcDispatcher;
    this.finsightStratsRpc = finsightStratsRpcDispatcher;
    this.externalRpc = externalRpcDispatcher;
    this.condorRpcDispatcher = condorRpcDispatcher;
  }

  async getEventLogOffset() {
    let req = new Request('admin.event_store.get_event_log_stats', { database_alias: 'es' });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  async getFinsightEventLogOffset() {
    let req = new Request('finsight_web.admin.database.get_event_log_stats', { database_alias: 'f_es' });
    let response = await this.finsightWebRpc.request(req);

    return response.getResult().payload;
  }

  async getFinsightStratsEventLogOffset() {
    let req = new Request('finsight_strats.admin.database.get_event_log_stats', { database_alias: 'f_s_es' });
    let response = await this.finsightStratsRpc.request(req);

    return response.getResult().payload;
  }

  async getFinsightAnalyticsEventLogOffset() {
    let req = new Request('finsight_web.admin.database.get_event_log_stats', { database_alias: 'f_a_es' });
    let response = await this.finsightWebRpc.request(req);

    return response.getResult().payload;
  }

  async getDmPortalEventLogOffset() {
    let req = new Request('admin.event_store.get_event_log_stats', { database_alias: 'dp_es' });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  async getCondorEventLogOffset() {
    let req = new Request('condor.admin.database.get_event_log_stats', { database_alias: 'condor_es' });
    let response = await this.condorRpcDispatcher.request(req);

    return response.getResult().payload;
  }

  async getDrsTableDataByFilter(filters) {
    let req = new Request('admin.database.get_data', filters);
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  async getFinsightWebTableDataByFilter(filters) {
    let req = new Request('finsight_web.admin.database.get_data', filters);
    let response = await this.finsightWebRpc.request(req);

    return response.getResult().payload;
  }

  async getFinsightStratsTableDataByFilter(filters) {
    let req = new Request('finsight_strats.admin.database.get_data', filters);
    let response = await this.finsightStratsRpc.request(req);

    return response.getResult().payload;
  }

  async getExternalTableDataByFilter(filters) {
    let req = new Request('internal.database.get_data', filters);
    let response = await this.externalRpc.request(req);

    return response.getResult().payload;
  }

  async getCondorTableDataByFilter(filters) {
    let req = new Request('condor.admin.database.get_data', filters);
    let response = await this.condorRpcDispatcher.request(req);

    return response.getResult().payload;
  }

  async getDrsDatabasesStructure() {
    let req = new Request('admin.database.get_structure');
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  async getFinsightWebDatabasesStructure() {
    let req = new Request('finsight_web.admin.database.get_structure', {});
    let response = await this.finsightWebRpc.request(req);

    return response.getResult().payload;
  }

  async getFinsightStratsDatabasesStructure() {
    let req = new Request('finsight_strats.admin.database.get_structure', {});
    let response = await this.finsightStratsRpc.request(req);

    return response.getResult().payload;
  }

  async getExternalDatabasesStructure() {
    let req = new Request('internal.database.get_structure', {});
    let response = await this.externalRpc.request(req);

    return response.getResult().payload;
  }

  async getCondorDatabasesStructure() {
    let req = new Request('condor.admin.database.get_structure', {});
    let response = await this.condorRpcDispatcher.request(req);

    return response.getResult().payload;
  }
}

export default DatabasesRepository;
