export const REQUEST_DEAL_SETTINGS = 'REQUEST_DEAL_SETTINGS';
export const REQUEST_DEAL_SETTINGS_SUCCESS = 'REQUEST_DEAL_SETTINGS_SUCCESS';
export const REQUEST_DEAL_SETTINGS_ERROR = 'REQUEST_DEAL_SETTINGS_ERROR';
export const REQUEST_DEAL_SETTINGS_UPDATE = 'REQUEST_DEAL_SETTINGS_UPDATE';
export const REQUEST_DEAL_SETTINGS_UPDATE_SUCCESS = 'REQUEST_DEAL_SETTINGS_UPDATE_SUCCESS';
export const REQUEST_DEAL_SETTINGS_UPDATE_ERROR = 'REQUEST_DEAL_SETTINGS_UPDATE_ERROR';
export const DEAL_SETTINGS_RESET = 'DEAL_SETTINGS_RESET';

export const REQUEST_ROADSHOW_MAPPING = 'REQUEST_ROADSHOW_MAPPING';
export const REQUEST_ROADSHOW_MAPPING_SUCCESS = 'REQUEST_ROADSHOW_MAPPING_SUCCESS';
export const REQUEST_ROADSHOW_MAPPING_ERROR = 'REQUEST_ROADSHOW_MAPPING_ERROR';
export const REQUEST_ROADSHOW_MAPPING_UPDATE = 'REQUEST_ROADSHOW_MAPPING_UPDATE';
export const REQUEST_ROADSHOW_MAPPING_UPDATE_SUCCESS = 'REQUEST_ROADSHOW_MAPPING_UPDATE_SUCCESS';
export const ROADSHOW_MAPPING_RESET = 'ROADSHOW_MAPPING_RESET';

export const SET_DEAL_SETTINGS_NEED_TO_REFRESH_MODAL_VISIBLE = 'SET_DEAL_SETTINGS_NEED_TO_REFRESH_MODAL_VISIBLE';

export const SET_DEAL_MAPPING_NEED_TO_REFRESH_MODAL_VISIBLE = 'SET_DEAL_MAPPING_NEED_TO_REFRESH_MODAL_VISIBLE';

export const SET_EVERCALL_CONFERENCE_ERROR = 'SET_EVERCALL_CONFERENCE_ERROR';
export const REQUEST_EVERCALL_DEAL_SETTINGS_SUCCESS = 'REQUEST_EVERCALL_DEAL_SETTINGS_SUCCESS';
