export const REQUEST_DEAL_FILES = 'REQUEST_DEAL_FILES';
export const REQUEST_DEAL_FILES_SUCCESS = 'REQUEST_DEAL_FILES_SUCCESS';
export const REQUEST_DEAL_FILES_ERROR = 'REQUEST_DEAL_FILES_ERROR';
export const REQUEST_DEAL_FILES_TOTAL_COUNT = 'REQUEST_DEAL_FILES_TOTAL_COUNT';
export const DEAL_FILES_RESET = 'DEAL_FILES_RESET';

export const DEAL_FILES_TABLE_FILTER_SEARCH = 'DEAL_FILES_TABLE_FILTER_SEARCH';
export const DEAL_FILES_TABLE_FILTER_PAGINATION = 'DEAL_FILES_TABLE_FILTER_PAGINATION';
export const DEAL_FILES_TABLE_FILTER_PER_PAGE = 'DEAL_FILES_TABLE_FILTER_PER_PAGE';
export const DEAL_FILES_TABLE_FILTER_RADIO = 'DEAL_FILES_TABLE_FILTER_RADIO';
export const DEAL_FILES_TABLE_SORT = 'DEAL_FILES_TABLE_SORT';
export const DEAL_FILES_TABLE_RESET = 'DEAL_FILES_TABLE_RESET';

export const REQUEST_FILE_TEMPORARY_DOWNLOAD_URL = 'REQUEST_FILE_TEMPORARY_DOWNLOAD_URL';
export const REQUEST_FILE_TEMPORARY_DOWNLOAD_URL_SUCCESS = 'REQUEST_FILE_TEMPORARY_DOWNLOAD_URL_SUCCESS';
export const REQUEST_FILE_TEMPORARY_DOWNLOAD_URL_ERROR = 'REQUEST_FILE_TEMPORARY_DOWNLOAD_URL_ERROR';
