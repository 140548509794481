export const GET_MANAGER_ANALYTICS_CONTACTS = 'GET_MANAGER_ANALYTICS_CONTACTS';
export const GET_MANAGER_ANALYTICS_CONTACTS_SUCCESS = 'GET_MANAGER_ANALYTICS_CONTACTS_SUCCESS';
export const GET_MANAGER_ANALYTICS_CONTACTS_ERROR = 'GET_MANAGER_ANALYTICS_CONTACTS_ERROR';

export const SET_MANAGER_ANALYTICS_CONTACTS_TABLE_SORT = 'SET_MANAGER_ANALYTICS_CONTACTS_TABLE_SORT';
export const SET_MANAGER_ANALYTICS_CONTACTS_TABLE_ITEMS_PER_PAGE = 'SET_MANAGER_ANALYTICS_CONTACTS_TABLE_ITEMS_PER_PAGE';
export const SET_MANAGER_ANALYTICS_CONTACTS_TABLE_PAGE = 'SET_MANAGER_ANALYTICS_CONTACTS_TABLE_PAGE';

export const RESET_MANAGER_ANALYTICS_CONTACTS = 'RESET_MANAGER_ANALYTICS_CONTACTS';

export const EXPORT_TO_EXCEL_MANAGER_ANALYTICS_CONTACTS = 'EXPORT_TO_EXCEL_MANAGER_ANALYTICS_CONTACTS';
export const EXPORT_TO_EXCEL_MANAGER_ANALYTICS_CONTACTS_SUCCESS = 'EXPORT_TO_EXCEL_MANAGER_ANALYTICS_CONTACTS_SUCCESS';
export const EXPORT_TO_EXCEL_MANAGER_ANALYTICS_CONTACTS_ERROR = 'EXPORT_TO_EXCEL_MANAGER_ANALYTICS_CONTACTS_ERROR';
