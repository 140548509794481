import TRANCHE_FIELDS from './trancheFields';

export const DEAL_PROFILE_FIELDS = {
  DEAL_ID: {
    name: 'dealId',
  },
  TIME_ZONE_ID: {
    name: 'timeZoneId',
  },
  // DEAL DETAILS
  PRIMARY_ISSUER: {
    name: 'primaryIssuerId',
    label: 'Issuer Name',
    isSelect: true,
  },
  PARENT: {
    name: 'parentId',
    label: 'Parent / Sponsor',
    isSelect: true,
  },
  SERIES: {
    name: 'seriesName',
    label: 'Series',
  },
  COUNTRY: {
    name: 'countryId',
    label: 'Country',
    isSelect: true,
  },
  PRIMARY_REGION: { // non editable
    name: 'primaryRegionId',
    label: 'Primary Region',
    isSelect: true,
  },
  PRODUCT: {
    name: 'productId',
    label: 'Product',
    isSelect: true,
  },
  ABS_SECTOR_SUBSECTOR: {
    name: 'sectorSubsector',
    label: 'ABS Sector/Subsector',
    isSelect: true,
  },
  ANNOUNCED_DATE: {
    name: 'announcedDate',
    label: 'Announced',
    isDate: true,
  },
  PRICING_DATE: {
    name: 'pricingDate',
    label: 'Pricing',
    isDate: true,
  },
  SETTLEMENT_DATE: {
    name: 'settlementDate',
    label: 'Settlement',
    isDate: true,
  },
  VERIFIED: {
    name: 'verified',
    label: 'Verified',
    dataTest: 'dealVerifiedCheckbox',
    isBoolean: true,
  },
  ADDITIONAL_ISSUERS: {
    name: 'additionalIssuerIds',
    label: 'Additional Issuers',
    isMultiSelect: true,
  },
  ADDITIONAL_REGIONS: {
    name: 'regionIds',
    label: 'Additional Regions',
    isMultiSelect: true,
  },
  STRUCTURING_LEADS: {
    name: 'structuringLeads',
    label: 'Structuring Leads',
    isMultiSelect: true,
  },
  JOINT_LEADS: {
    name: 'jointLeads',
    label: 'Joint Leads',
    isMultiSelect: true,
  },
  INV_SET_AVAILABLE: {
    name: 'investorSetAvailable',
    dataTest: 'dealInvestorSetAvailableCheckbox',
    label: 'Invset',
    labelTooltipContent: 'InvestorSet available for this deal',
    isBoolean: true,
  },
  // TRANCHES
  TRANCHES: {
    name: 'tranches',
    label: 'Tranches',
  },
  TRANCHE: TRANCHE_FIELDS,
  // ADDITIONAL_DEAL_PROPERTIES
  // ABS
  CUT_OFF_BALANCE: {
    name: 'cutOffBalance',
    dataTest: 'cutOffBalance',
    label: 'Cut-Off Balance (m)',
    isNumber: true,
  },
  EXP_PRICING_DATE: {
    name: 'expectedPricingDate',
    dataTest: 'expectedPricingDate',
    label: 'Exp. Pricing Date',
    labelTooltipContent: 'Expected Pricing Date',
    isDate: true,
  },
  EXP_RATINGS_DATE: {
    name: 'expectedRatingsDate',
    dataTest: 'expectedRatingsDate',
    label: 'Exp. Ratings Date',
    labelTooltipContent: 'Expected Ratings Date',
    isDate: true,
  },
  EXP_SETTLEMENT_DATE: {
    name: 'expectedSettlementDate',
    dataTest: 'expectedSettlementDate',
    label: 'Exp. Settlement Date',
    labelTooltipContent: 'Expected Settlement Date',
    isDate: true,
  },
  PRICING_SPEED: {
    name: 'pricingSpeed',
    label: 'Pricing Speed',
  },
  // CMBS
  NUMBER_OF_LOANS: {
    name: 'numberOfLoans',
    label: '# of Loans',
    isNumber: true,
  },
  NUMBER_OF_PROPERTIES: {
    name: 'numberOfProperties',
    label: '# of Properties',
    isNumber: true,
  },
  TOP_10_LOANS: {
    name: 'top10Loans',
    label: 'Top 10 Loans %',
  },
  WA_CUT_OFF_LTV: {
    name: 'waCutOffLtv',
    label: 'WA Cut-Off LTV',
    labelTooltipContent: 'Weighted Average Cut-Off Loan-to-Value',
  },
  WA_MATURITY_LTV: {
    name: 'waMaturityLtv',
    label: 'WA MAT. LTV',
    labelTooltipContent: 'Weighted Average Maturity Loan-to-Value',
  },
  WA_REM_AMRT: {
    name: 'waRemAmrtTermMonths',
    label: 'WA REM AMRT',
    labelTooltipContent: 'Weighted Average Remaining Amortization Term (Months)',
  },
  WA_SEASONING_MONTHS: {
    name: 'waSeasoningMonths',
    label: 'WA Seasoning (Months)',
    labelTooltipContent: 'Weighted Average Seasoning (Months)',
  },
  WA_TERM_TO_MAT: {
    name: 'waTermToMaturityMonths',
    label: 'WA TERM MAT.',
    labelTooltipContent: 'Weighted Average Term to Maturity',
  },
  WA_UW_DSCR: {
    name: 'waUWDscr',
    label: 'WA U/W DSCR',
    labelTooltipContent: 'Weighted Average Underwritten Debt Service Coverage Ratio',
  },
  WA_UW_NOI_DEBT_YIELD: {
    name: 'waUWNoiDebtYield',
    label: 'WA U/W NOI Debt Yield',
    labelTooltipContent: 'Weighted Average Underwritten Net Operating Income Debt Yield',
  },
  // CLO
  REINVESTMENT_PERIOD: {
    name: 'reinvestmentPeriod',
    label: 'Reinvest. PD (YRS)',
    labelTooltipContent: 'Reinvestment Period (Years)',
    isNumber: true,
  },
  REINVESTMENT_PERIOD_END_DATE: {
    name: 'reinvestmentPeriodEndDate',
    dataTest: 'reinvestmentPeriodEndDate',
    label: 'Reinvest. PD End Date',
    labelTooltipContent: 'Reinvestment Period End Date',
    isDate: true,
  },
  REFINANCING: {
    name: 'refinancing',
    label: 'Refinancing',
    dataTest: 'CLORefinancingCheckbox',
    isBoolean: true,
  },
  RESET: {
    name: 'reset',
    label: 'Reset',
    dataTest: 'CLOResetCheckbox',
    isBoolean: true,
  },
  WAL_TEST_PD: {
    name: 'walTestPeriod',
    dataTest: 'walTestPeriod',
    label: 'WAL Test PD (Yrs)',
    labelTooltipContent: 'Weighted Average Life Test Period (Years)',
    isNumber: true,
  },
  WAL_TEST_END_DATE: {
    name: 'walTestEndDate',
    dataTest: 'walTestEndDate',
    label: 'WAL Test PD End Date',
    labelTooltipContent: 'Weighted Average Life Test Period End Date',
    isDate: true,
  },
  // OTHER
  MIN_DENOMINATION: {
    name: 'minDenomination',
    label: 'Min. Denomination',
  },

  // DEAL COMMENTS
  COLLATERAL: {
    name: 'collateral',
    dataTest: 'collateral',
    label: 'Collateral',
  },
  COMMENTS: {
    name: 'comments',
    dataTest: 'comments',
    label: 'Comments',
  },
  GUARANTOR: {
    name: 'guarantor',
    dataTest: 'guarantor',
    label: 'Guarantors',
  },
  ORIGINATOR: {
    name: 'originator',
    dataTest: 'originator',
    label: 'Originators',
  },
  RISK_RETENTION: {
    name: 'riskRetention',
    dataTest: 'riskRetention',
    label: 'Risk Retention',
  },
  USE_OF_PROCEEDS: {
    name: 'useOfProceeds',
    dataTest: 'useOfProceeds',
    label: 'Use of Proceeds',
  },
  VOLCKER_COMPLIANCE: {
    name: 'volckerCompliance',
    dataTest: 'volckerCompliance',
    label: 'Volcker Compliance',
  },
};
