import * as actionsTypes from '../actionTypes';

const initialState = {
  search: '',
  radio: {
    default: 'all',
  },
  paginationGroup: {
    perPage: 25,
    activePage: 1,
  },
  sort: {
    sortBy: 'date',
    sortOrder: 'desc',
  },
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
export default function tableFilter(state = initialState, action) {
  switch (action.type) {
    case actionsTypes.ROADSHOWS_TABLE_RESET:
      return initialState;
    case actionsTypes.ROADSHOWS_TABLE_FILTER_SEARCH:
      return {
        ...state,
        search: action.payload.value,
        paginationGroup: {
          ...state.paginationGroup,
          activePage: 1,
        },
      };
    case actionsTypes.ROADSHOWS_TABLE_FILTER_PAGINATION:
      return {
        ...state,
        paginationGroup: {
          ...state.paginationGroup,
          activePage: action.payload.value,
        },
      };
    case actionsTypes.ROADSHOWS_TABLE_FILTER_PER_PAGE:
      return {
        ...state,
        paginationGroup: {
          ...state.paginationGroup,
          perPage: action.payload.value,
          activePage: 1,
        },
      };
    case actionsTypes.ROADSHOWS_TABLE_FILTER_RADIO:
      return {
        ...state,
        radio: {
          ...state.radio,
          [action.payload.group]: action.payload.value,
        },
        paginationGroup: {
          ...state.paginationGroup,
          activePage: 1,
        },
      };
    case actionsTypes.ROADSHOWS_TABLE_SORT:
      return {
        ...state,
        sort: {
          sortBy: action.payload.sortBy,
          sortOrder: action.payload.sortOrder,
        },
        paginationGroup: {
          ...state.paginationGroup,
          activePage: 1,
        },
      };
    default:
      return state;
  }
}
