import get from 'lodash/get';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import createAction from '@/Framework/State/Redux/createAction';

export const defaultActionTypesConfig = {
  withGet: true,
  withCreate: false,
  withUpdate: false,
  withRemove: false,
};

/**
 * @deprecated all the functions exported from 'itemFactory.js' are deprecated.
 * Please use useItemFactory() hook instead.
 *
 * @param {String} itemActionTypeKeyWord
 * @param {Object.<String, Boolean>} [actionTypesConfig]
 * @param {Boolean} [actionTypesConfig.withGet]
 * @param {Boolean} [actionTypesConfig.withCreate]
 * @param {Boolean} [actionTypesConfig.withUpdate]
 * @param {Boolean} [actionTypesConfig.withRemove]
 */
export const getItemActionTypes = (itemActionTypeKeyWord, actionTypesConfig = {}) => {
  const config = { ...defaultActionTypesConfig, ...actionTypesConfig };

  const getActionTypes = {
    GET_ITEM: `GET_${ itemActionTypeKeyWord }_ITEM`,
    GET_ITEM_SUCCESS: `GET_${ itemActionTypeKeyWord }_ITEM_SUCCESS`,
    GET_ITEM_ERROR: `GET_${ itemActionTypeKeyWord }_ITEM_ERROR`,
  };

  const createActionTypes = {
    CREATE_ITEM: `CREATE_${ itemActionTypeKeyWord }_ITEM`,
    CREATE_ITEM_SUCCESS: `CREATE_${ itemActionTypeKeyWord }_ITEM_SUCCESS`,
    CREATE_ITEM_ERROR: `CREATE_${ itemActionTypeKeyWord }_ITEM_ERROR`,
  };

  const updateActionTypes = {
    UPDATE_ITEM: `UPDATE_${ itemActionTypeKeyWord }_ITEM`,
    UPDATE_ITEM_SUCCESS: `UPDATE_${ itemActionTypeKeyWord }_ITEM_SUCCESS`,
    UPDATE_ITEM_ERROR: `UPDATE_${ itemActionTypeKeyWord }_ITEM_ERROR`,
  };

  const removeActionTypes = {
    REMOVE_ITEM: `REMOVE_${ itemActionTypeKeyWord }_ITEM`,
    REMOVE_ITEM_SUCCESS: `REMOVE_${ itemActionTypeKeyWord }_ITEM_SUCCESS`,
    REMOVE_ITEM_ERROR: `REMOVE_${ itemActionTypeKeyWord }_ITEM_ERROR`,
  };

  return {
    SET_CURRENT_ITEM: `SET_${ itemActionTypeKeyWord }_CURRENT_ITEM`,
    RESET: `RESET_${ itemActionTypeKeyWord }_ITEM`,
    ...(config.withGet && getActionTypes),
    ...(config.withCreate && createActionTypes),
    ...(config.withUpdate && updateActionTypes),
    ...(config.withRemove && removeActionTypes),
  };
};

/**
 * @deprecated all the functions exported from 'itemFactory.js' are deprecated.
 * Please use useItemFactory() hook instead.
 *
 * @typedef AsyncRequestConfigObject
 * @property {Function} method
 * @property {Object} [callbacks]
 *
 * @param {Object} actionTypes
 *
 * @param {Object} [asyncRequestsConfig]
 * @param {AsyncRequestConfigObject} [asyncRequestsConfig.get]
 * @param {AsyncRequestConfigObject} [asyncRequestsConfig.create]
 * @param {AsyncRequestConfigObject} [asyncRequestsConfig.update]
 * @param {AsyncRequestConfigObject} [asyncRequestsConfig.remove]
 */
export const getItemActions = (actionTypes, asyncRequestsConfig = {}) => {
  const { get, create, update, remove } = asyncRequestsConfig;
  return (
    {
      setCurrentItem: (payload) => createAction(actionTypes.SET_CURRENT_ITEM, payload),
      reset: () => createAction(actionTypes.RESET),
      ...(actionTypes.GET_ITEM &&
        {
          getItem: (payload) => createAsyncRequest(
            {
              payload,
              method: get.method,
              types: [actionTypes.GET_ITEM, actionTypes.GET_ITEM_SUCCESS, actionTypes.GET_ITEM_ERROR],
              callbacks: get.callbacks,
            },
          ),
        }
      ),
      ...(actionTypes.CREATE_ITEM &&
        {
          createItem: (payload) => createAsyncRequest(
            {
              payload,
              method: create.method,
              types: [actionTypes.CREATE_ITEM, actionTypes.CREATE_ITEM_SUCCESS, actionTypes.CREATE_ITEM_ERROR],
              callbacks: create.callbacks,
            },
          ),
        }
      ),
      ...(actionTypes.UPDATE_ITEM &&
        {
          updateItem: (payload) => createAsyncRequest(
            {
              payload,
              method: update.method,
              types: [actionTypes.UPDATE_ITEM, actionTypes.UPDATE_ITEM_SUCCESS, actionTypes.UPDATE_ITEM_ERROR],
              callbacks: update.callbacks,
            },
          ),
        }
      ),
      ...(actionTypes.REMOVE_ITEM &&
        {
          removeItem: (payload) => createAsyncRequest(
            {
              payload,
              method: remove.method,
              types: [actionTypes.REMOVE_ITEM, actionTypes.REMOVE_ITEM_SUCCESS, actionTypes.REMOVE_ITEM_ERROR],
              callbacks: remove.callbacks,
            },
          ),
        }
      ),
    }
  );
};

/**
 * @deprecated all the functions exported from 'itemFactory.js' are deprecated.
 * Please use useItemFactory() hook instead.
 *
 * @param {Object} actionTypes
 * @param {Object} [newState]
 */
export const getItemReducerInitialState = (actionTypes, newState = {}) => (
  {
    data: {},
    ...(actionTypes.GET_ITEM && { isFetching: false }),
    ...(actionTypes.CREATE_ITEM && { isCreating: false }),
    ...(actionTypes.UPDATE_ITEM && { isUpdating: false }),
    ...(actionTypes.REMOVE_ITEM && { isRemoving: false }),
    ...newState,
  }
);

/**
 * @deprecated all the functions exported from 'itemFactory.js' are deprecated.
 * Please use useItemFactory() hook instead.
 *
 * @param {Object} actionTypes
 * @param {Object} [newState]
 * @param {Object.<String, Function>} [additionalCases]
 */
export const getItemReducer = (actionTypes, newState = {}, additionalCases = {}) => {
  const initialState = getItemReducerInitialState(actionTypes, newState);
  return (state = initialState, action) => {
    if (additionalCases[action.type]) {
      return additionalCases[action.type](state, action);
    }

    switch (action.type) {
      case actionTypes.SET_CURRENT_ITEM:
        return {
          ...state,
          data: action.payload,
        };
      case actionTypes.GET_ITEM:
        return {
          ...state,
          isFetching: true,
        };
      case actionTypes.GET_ITEM_SUCCESS:
        return {
          ...state,
          isFetching: false,
          data: action.payload,
        };
      case actionTypes.GET_ITEM_ERROR:
        return {
          ...state,
          isFetching: false,
        };
      case actionTypes.CREATE_ITEM:
        return {
          ...state,
          isCreating: true,
        };
      case actionTypes.CREATE_ITEM_SUCCESS:
        return {
          ...state,
          isCreating: false,
        };
      case actionTypes.CREATE_ITEM_ERROR:
        return {
          ...state,
          isCreating: false,
        };
      case actionTypes.UPDATE_ITEM:
        return {
          ...state,
          isUpdating: true,
        };
      case actionTypes.UPDATE_ITEM_SUCCESS:
        return {
          ...state,
          isUpdating: false,
        };
      case actionTypes.UPDATE_ITEM_ERROR:
        return {
          ...state,
          isUpdating: false,
        };
      case actionTypes.REMOVE_ITEM:
        return {
          ...state,
          isRemoving: true,
        };
      case actionTypes.REMOVE_ITEM_SUCCESS:
        return {
          ...state,
          isRemoving: false,
        };
      case actionTypes.REMOVE_ITEM_ERROR:
        return {
          ...state,
          isRemoving: false,
        };
      case actionTypes.RESET:
        return initialState;
      default:
        return state;
    }
  };
};

/**
 * @deprecated all the functions exported from 'itemFactory.js' are deprecated.
 * Please use useItemFactory() hook instead.
 *
 * @param {String} path
 * @param [defaultValue]
 */
const createSelector = (path, defaultValue) => (state) => get(state, path, defaultValue);

/**
 * @deprecated all the functions exported from 'itemFactory.js' are deprecated.
 * Please use useItemFactory() hook instead.
 *
 * @param {String} pathToReducerState
 * @param {Object} [actionTypes]
 */
export const getItemSelectors = (pathToReducerState, actionTypes = {}) => (
  {
    getItemData: createSelector(`${ pathToReducerState }.data`, []),
    ...(actionTypes.GET_ITEM && { isFetching: createSelector(`${ pathToReducerState }.isFetching`, false) }),
    ...(actionTypes.CREATE_ITEM && { isCreating: createSelector(`${ pathToReducerState }.isCreating`, false) }),
    ...(actionTypes.UPDATE_ITEM && { isUpdating: createSelector(`${ pathToReducerState }.isUpdating`, false) }),
    ...(actionTypes.REMOVE_ITEM && { isRemoving: createSelector(`${ pathToReducerState }.isRemoving`, false) }),
  }
);
