import { getItemReducer } from '@/Framework/State/Redux/itemFactory';
import actionTypes from './actionTypes';

const newState = {
  isInlineEditing: false,
  isDeleteModalVisible: false,
  isMigrateModalVisible: false,
  isDealsFetching: false,
  isDealsMigrating: false,
  isInformationForMigrationFetching: false,
  informationForMigration: {},
};

export default getItemReducer(
  actionTypes,
  newState,
  {
    [actionTypes.SET_CONDOR_FINSIGHT_COMPANY_IS_INLINE_EDITING]: (state, action) => (
      { ...state, isInlineEditing: action.payload }
    ),
    [actionTypes.SET_CONDOR_FINSIGHT_COMPANY_IS_DELETE_MODAL_VISIBLE]: (state, action) => (
      { ...state, isDeleteModalVisible: action.payload }
    ),
    [actionTypes.SET_CONDOR_FINSIGHT_COMPANY_IS_MIGRATE_MODAL_VISIBLE]: (state, action) => (
      { ...state, isMigrateModalVisible: action.payload }
    ),
    [actionTypes.GET_CONDOR_FINSIGHT_COMPANY_DEALS]: (state) => (
      { ...state, isDealsFetching: true }
    ),
    [actionTypes.GET_CONDOR_FINSIGHT_COMPANY_DEALS_SUCCESS]: (state) => (
      { ...state, isDealsFetching: false }
    ),
    [actionTypes.GET_CONDOR_FINSIGHT_COMPANY_DEALS_ERROR]: (state) => (
      { ...state, isDealsFetching: false }
    ),
    [actionTypes.MIGRATE_CONDOR_FINSIGHT_COMPANY_DEALS]: (state) => (
      { ...state, isDealsMigrating: true }
    ),
    [actionTypes.MIGRATE_CONDOR_FINSIGHT_COMPANY_DEALS_ERROR]: (state) => (
      { ...state, isDealsMigrating: false }
    ),
    [actionTypes.MIGRATE_CONDOR_FINSIGHT_COMPANY_DEALS_SUCCESS]: (state) => (
      { ...state, isDealsMigrating: false }
    ),
    [actionTypes.GET_COMPANY_INFORMATION_FOR_MIGRATION]: (state) => (
      { ...state, isInformationForMigrationFetching: true }
    ),
    [actionTypes.GET_COMPANY_INFORMATION_FOR_MIGRATION_SUCCESS]: (state, action) => ({
      ...state,
      isInformationForMigrationFetching: false,
      informationForMigration: {
        ...action.payload,
      },
    }),
    [actionTypes.GET_COMPANY_INFORMATION_FOR_MIGRATION_ERROR]: (state) => ({
      ...state, isInformationForMigrationFetching: false,
    }),
  },
);
