import * as actionTypes from '../actionTypes';

const initialState = {
  isFetching: false,
  timeZone: {
    id: '',
    name: 'Eastern Time',
    timeZone: 'America/New_York',
    abbr: 'ET',
    default: 1,
  },
  list: [],
  totalCount: 0,
};

export default function analytics(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ANALYTICS_RESET:
      return initialState;
    case actionTypes.REQUEST_ANALYTICS:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_ANALYTICS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.collection,
        timeZone: action.payload.timeZone,
      };
    case actionTypes.REQUEST_ANALYTICS_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case actionTypes.REQUEST_ANALYTICS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
