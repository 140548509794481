import { combineReducers } from 'redux';
import collection from './collection/reducer';
import item from './item/reducer';
import deals from './deals/reducer';
import filteredCompaniesOptionsByFields from './filteredCompaniesOptionsByFields/reducer';

export default combineReducers(
  {
    collection,
    item,
    deals,
    filteredCompaniesOptionsByFields,
  },
);
