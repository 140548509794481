export default {
  SET_MANAGE_SUBSCRIPTION_MODAL_DATA: 'SET_MANAGE_SUBSCRIPTION_MODAL_DATA',
  SET_IS_SUBSCRIBE_MODAL_VISIBLE: 'SET_IS_SUBSCRIBE_MODAL_VISIBLE',
  CONDOR_FINSIGHT_SUBSCRIBE: 'CONDOR_FINSIGHT_SUBSCRIBE',
  CONDOR_FINSIGHT_SUBSCRIBE_ERROR: 'CONDOR_FINSIGHT_SUBSCRIBE_ERROR',
  CONDOR_FINSIGHT_SUBSCRIBE_SUCCESS: 'CONDOR_FINSIGHT_SUBSCRIBE_SUCCESS',
  GET_SUBSCRIPTIONS: 'GET_SUBSCRIPTIONS',
  GET_SUBSCRIPTIONS_SUCCESS: 'GET_SUBSCRIPTIONS_SUCCESS',
  GET_SUBSCRIPTIONS_ERROR: 'GET_SUBSCRIPTIONS_ERROR',
  RESET_METADATA: 'RESET_METADATA',
};
