import { getCollectionActionTypes } from '@/Framework/State/Redux/collectionFactory';

export default {
  ...getCollectionActionTypes(
    'CONDOR_FINSIGHT_COMPANY_DEALS',
    {
      withSort: true,
      withSearch: true,
    },
  ),
  GET_CONDOR_DEALS_LIST_OPTIONS: 'GET_CONDOR_DEALS_LIST_OPTIONS',
  GET_CONDOR_DEALS_LIST_OPTIONS_SUCCESS: 'GET_CONDOR_DEALS_LIST_OPTIONS_SUCCESS',
  GET_CONDOR_DEALS_LIST_OPTIONS_ERROR: 'GET_CONDOR_DEALS_LIST_OPTIONS_ERROR',
  SET_CONDOR_DEALS_LIST_FILTERS_PRODUCTS: 'SET_CONDOR_DEALS_LIST_FILTERS_PRODUCTS',
};
