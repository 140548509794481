export const GET_BANNERS_LIST = 'GET_BANNERS_LIST';
export const GET_BANNERS_LIST_SUCCESS = 'GET_BANNERS_LIST_SUCCESS';
export const GET_BANNERS_LIST_ERROR = 'GET_BANNERS_LIST_ERROR';

export const GET_BANNERS_POSITIONS_LIST = 'GET_BANNERS_POSITIONS_LIST';
export const GET_BANNERS_POSITIONS_LIST_SUCCESS = 'GET_BANNERS_POSITIONS_LIST_SUCCESS';
export const GET_BANNERS_POSITIONS_LIST_ERROR = 'GET_BANNERS_POSITIONS_LIST_ERROR';

export const SUBMIT_CREATE_BANNER_FORM = 'SUBMIT_CREATE_BANNER_FORM';
export const SUBMIT_CREATE_BANNER_SUCCESS = 'SUBMIT_CREATE_BANNER_SUCCESS';
export const SUBMIT_CREATE_BANNER_ERROR = 'SUBMIT_CREATE_BANNER_ERROR';

export const SUBMIT_UPDATE_BANNER_FORM = 'SUBMIT_UPDATE_BANNER_FORM';
export const SUBMIT_UPDATE_BANNER_SUCCESS = 'SUBMIT_UPDATE_BANNER_SUCCESS';
export const SUBMIT_UPDATE_BANNER_ERROR = 'SUBMIT_UPDATE_BANNER_ERROR';

export const SUBMIT_DELETE_BANNER_FORM = 'SUBMIT_DELETE_BANNER_FORM';
export const SUBMIT_DELETE_BANNER_SUCCESS = 'SUBMIT_DELETE_BANNER_SUCCESS';
export const SUBMIT_DELETE_BANNER_ERROR = 'SUBMIT_DELETE_BANNER_ERROR';
export const DELETE_BANNER_FROM_STORE = 'DELETE_BANNER_FROM_STORE';
