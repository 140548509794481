const BANNER_STATUS = {
  LIVE: 'live',
  NOT_LIVE: 'not_live',
};

class Banner {
  /**
   * @return {String}
   */
  static getLive() {
    return BANNER_STATUS.LIVE;
  }

  /**
   * @return {String}
   */
  static getNotLive() {
    return BANNER_STATUS.NOT_LIVE;
  }

  /**
   * @param {String} bannerStatus
   * @return {Boolean}
   */
  static isLive(bannerStatus) {
    return BANNER_STATUS.LIVE === bannerStatus;
  }

  /**
   * @param {String} bannerStatus
   * @return {Boolean}
   */
  static isNotLive(bannerStatus) {
    return BANNER_STATUS.NOT_LIVE === bannerStatus;
  }

  /**
   * @param {String} status
   * @return {String}
   */
  static statusTooltip(status) {
    const statuses = {
      live: 'Live',
      not_live: 'Not live',
    };
    return statuses[status];
  }
}

export default Banner;
