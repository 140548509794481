import { getCollectionActionTypes } from '@/Framework/State/Redux/collectionFactory';

export default {
  ...getCollectionActionTypes('CONDOR_FINSIGHT_FILINGS', {
    withSort: true,
    withSearch: true,
  }),
  SET_DEAL_MAPPING: 'SET_DEAL_MAPPING',
  SET_IS_DISABLE_MODAL_VISIBLE: 'SET_IS_DISABLE_MODAL_VISIBLE',
  SET_IS_SEND_EMAIL_MODAL_VISIBLE: 'SET_IS_SEND_EMAIL_MODAL_VISIBLE',
  SET_IS_RESEND_EMAIL_MODAL_VISIBLE: 'SET_IS_RESEND_EMAIL_MODAL_VISIBLE',
  HIDE_MODAL: 'HIDE_MODAL',
  UPDATE_STATUS: 'UPDATE_STATUS',
  UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
  UPDATE_STATUS_ERROR: 'UPDATE_STATUS_ERROR',
};
