import { combineReducers } from 'redux';
import upload from './upload/reducer';
import validation from './validation/reducer';
import list from './list/reducer';
import bwic from './bwic/reducer';
import inventory from './inventory/reducer';

export default combineReducers(
  {
    bwic,
    inventory,
    upload,
    validation,
    list,
  },
);
