import { getCollectionActionTypes } from '@/Framework/State/Redux/collectionFactory';

export default {
  ...getCollectionActionTypes('CONDOR_FINSIGHT_IMPORT_COMPANIES', {
    withSort: true,
  }),
  DELETE_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL: 'DELETE_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL',
  DELETE_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_SUCCESS:
    'DELETE_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_SUCCESS',
  DELETE_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_ERROR: 'DELETE_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_ERROR',

  SET_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_DELETE_ITEM:
    'SET_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_DELETE_ITEM',
  CHANGE_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_DELETE_MODAL_VISIBLE:
    'CHANGE_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_DELETE_MODAL_VISIBLE',
};
