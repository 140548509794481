import * as actionTypes from '../actionTypes';

const initialState = {
  isFetching: false,
  list: [],
  totalCount: 0,
};

export default function all(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ROADSHOWS_RESET:
      return initialState;
    case actionTypes.REQUEST_ROADSHOWS:
    case actionTypes.REQUEST_TOGGLE_ROADSHOWS_ACTIONS:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_TOGGLE_ROADSHOWS_ACTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.REQUEST_ROADSHOWS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case actionTypes.REQUEST_ROADSHOWS_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case actionTypes.REQUEST_ROADSHOWS_ERROR:
    case actionTypes.REQUEST_TOGGLE_ROADSHOWS_ACTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
