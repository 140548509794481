import * as actionTypes from './actionTypes';
import createAction from '@/Framework/State/Redux/createAction';

/**
 *
 * @param {{name: String, url: String}} payload
 * @return {function(*)}
 */
export function setBreadcrumb(payload) {
  return (dispatch) => {
    dispatch(createAction(actionTypes.BREADCRUMB_ADD, payload));
  };
}

/**
 * @param {String} payload
 * @return {function(*)}
 */
export function removeBreadcrumb(payload) {
  return (dispatch) => {
    dispatch(createAction(actionTypes.BREADCRUMB_REMOVE, payload));
  };
}
