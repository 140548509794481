import { combineReducers } from 'redux';
import accountTypes from '@/condor/application/actions/accountTypes/reducer';
import contacts from '@/condor/application/actions/contacts/reducer';
import blacklists from '@/condor/application/actions/blacklists/reducer';
import accounts from '@/condor/application/actions/accounts/reducer';
import banners from '@/condor/application/actions/banners/reducer';
import finsight from './finsight/reducer';
import finsightManager from './finsightManager/reducer';
import sidebar from '@/condor/application/actions/sidebar/reducer';

export default combineReducers({
  banners,
  accounts,
  contacts,
  accountTypes,
  blacklists,
  finsight,
  finsightManager,
  sidebar,
});
