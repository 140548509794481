import { connect } from 'react-redux';
import PageTitle from './PageTitle';

function mapStateToProps(state) {
  return {
    pageTitle: state.pageTitle,
  };
}

export default connect(
  mapStateToProps,
  null,
)(PageTitle);
