import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: true,
  data: [],
  totalCount: 0,
  sortBy: '',
  sortOrder: '',
  page: 1,
  perPage: 25,
};

export default function inventory(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CONDOR_INVENTORY_LIST:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_CONDOR_INVENTORY_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.collection,
        totalCount: action.payload.totalCount,
      };
    case actionTypes.GET_CONDOR_INVENTORY_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.CONDOR_INVENTORY_SORT_CHANGE:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
        page: 1,
      };
    case actionTypes.CONDOR_INVENTORY_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload,
      };
    case actionTypes.CONDOR_INVENTORY_PER_PAGE_CHANGE:
      return {
        ...state,
        perPage: action.payload,
        page: 1,
      };
    case actionTypes.CONDOR_INVENTORY_RESET_PARTIAL:
      return {
        ...state,
        isFetching: true,
        sortBy: '',
        sortOrder: '',
        page: 1,
      };
    case actionTypes.CONDOR_INVENTORY_RESET:
      return initialState;
    default:
      return state;
  }
}
