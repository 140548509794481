import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: true,
  navigationItems: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CONDOR_NAVIGATION_ITEMS:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_CONDOR_NAVIGATION_ITEMS_SUCCESS:
      return {
        ...state,
        navigationItems: action.payload,
        isFetching: false,
      };
    case actionTypes.GET_CONDOR_NAVIGATION_ITEMS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
