import { Component } from 'react';
import { FieldArray, reduxForm } from 'redux-form';
import convertFromFormsArrayToJsonQuery from '@/condor/ui/components/TechnicalSupport/modules/Databases/helpers/converter';
import QueryFilterPrepareModal from './QueryFilterPrepareModal';
import NotificationManager from '@/ui/shared/components/Notification/NotificationManager';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import Forms from './Forms/Forms';

import styles from './queryFilterForm.scss';

interface IProps {
  activeForm: number,
  forms: { [key: string]: any }[],
  logicOperators: { [key: string]: any }[],
  conditionalOperators: { [key: string]: any }[],
  changeFilter: (result: { [key: string]: any }) => void,
  resetState: () => void,
  reloadSalt?: () => void,
  reset?: () => void,
  array?: any[],
  change?: (name: string, value: number) => void,
}

class QueryFilterForm extends Component<IProps, { isShowPrepare: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      isShowPrepare: false,
    };
    this.addNewField = this.addNewField.bind(this);
    this.getLogicOperators = this.getLogicOperators.bind(this);
    this.getConditionalOperators = this.getConditionalOperators.bind(this);
    this.handleConvert = this.handleConvert.bind(this);
    this.getPrepareConvert = this.getPrepareConvert.bind(this);
    this.isShowPrepareModal = this.isShowPrepareModal.bind(this);
    this.onOpenPrepareModal = this.onOpenPrepareModal.bind(this);
    this.onClosePrepareModal = this.onClosePrepareModal.bind(this);
    this.renderPrepareModal = this.renderPrepareModal.bind(this);

    this.handleAddNewForm = this.handleAddNewForm.bind(this);
    this.clear = this.clear.bind(this);
  }

  componentWillUnmount() {
    this.clear();
  }

  onOpenPrepareModal() {
    this.setState({
      isShowPrepare: true,
    });
  }

  onClosePrepareModal() {
    this.setState({
      isShowPrepare: false,
    });
  }

  getConditionalOperators() {
    return this.props.conditionalOperators;
  }

  getLogicOperators() {
    return this.props.logicOperators;
  }

  getPrepareConvert() {
    // FUCK METHOD, but release is coming
    let forms = JSON.parse(JSON.stringify(this.props.forms));
    let result = {};
    if (forms.length > 0) {
      result = convertFromFormsArrayToJsonQuery(forms);
    }
    return result;
  }

  handleConvert() {
    let result = this.getPrepareConvert();

    let error = false;
    let loForms = {};

    this.props.forms.forEach((form, formIndex) => {
      let loGroups = {};

      form.groups.forEach((group) => {
        let loChildren = {};

        group.children.forEach((child) => {
          loChildren[child.lo] = true;
        });
        loGroups[group.lo] = true;

        if (Object.keys(loChildren).length > 1) {
          error = true;
          NotificationManager.error(`Between fields has different logic operators. Path: ${ form.label }: ${ formIndex } -> group: ${ group.label }`);
        }
      });

      if (Object.keys(loGroups).length > 1) {
        error = true;
        NotificationManager.error(`Between groups has different logic operators. Path: ${ form.label }: ${ formIndex }`);
      }
      loForms[form.lo] = true;
    });

    if (Object.keys(loForms).length > 1) {
      error = true;
      NotificationManager.error('Between forms has different logic operators');
    }

    if (!error) {
      if (Object.keys(result).length > 0) {
        this.props.changeFilter(result);
      } else {
        this.props.changeFilter(null);
      }
      this.props.reloadSalt();
    }
  }

  clear() {
    this.props.reset();
    this.props.resetState();
  }

  /** Query fields modal* */

  isShowPrepareModal() {
    return this.state.isShowPrepare;
  }

  addNewField(data) {
    let activeForm = this.props.activeForm || 0;
    let suffixes = data.key.split('.');
    suffixes.shift();

    let newField = {
      lo: this.props.logicOperators[1].value,
      co: this.props.conditionalOperators[0].value,
      groupSuffix: suffixes.length > 0 ? `.${ suffixes.join('.') }` : '',
      value: data.value,
    };
    let newGroup = {
      lo: this.props.logicOperators[0].value,
      label: data.root,
      children: [newField],
    };
    let newForm = {
      lo: this.getLogicOperators()[0].value,
      label: 'Form',
      groups: [newGroup],
      newGroup: '',
    };

    if (this.props.forms.length === 0) {
      this.props.array.push('forms', newForm);
    } else {
      let form = this.props.forms[activeForm];
      let existGroup = false;
      let existGroupIndex = 0;

      form.groups.forEach((group, index) => {
        if (group.label === data.root) {
          existGroup = true;
          existGroupIndex = index;
        }
      });

      if (!existGroup) {
        this.props.array.push(`forms[${ activeForm }].groups`, newGroup);
      } else {
        this.props.array.push(`forms[${ activeForm }].groups[${ existGroupIndex }].children`, newField);
      }
    }
  }

  handleAddNewForm() {
    let newActiveForm = this.props.forms.length > 0 ? this.props.forms.length : 0;
    this.props.array.push('forms', {
      lo: this.getLogicOperators()[0].value,
      label: 'Form',
      groups: [],
      newGroup: '',
    });

    this.props.change('activeForm', newActiveForm);
  }

  renderPrepareModal() {
    if (this.isShowPrepareModal()) {
      return (
        <QueryFilterPrepareModal
          showItem={ this.getPrepareConvert() }
          onClose={ this.onClosePrepareModal }
          onApply={ this.handleConvert }
        />
      );
    }
    return undefined;
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <form
        onSubmit={ () => {} }
        autoComplete="off"
        data-test="queryFilterForm"
      >
        <FieldArray
          name="forms"
          component={ Forms }
          logicOperators={ this.getLogicOperators() }
          conditionalOperators={ this.getConditionalOperators() }
          onFormChange={ this.props.change }
          onFormArray={ this.props.array }
        />
        <div className={ styles.actions }>
          <div className={ styles.primaryActions }>
            <Button
              variant={ variantTypes.success }
              onClick={ this.handleAddNewForm }
              title="Add query form"
              dataTest="queryFilterFormAddQueryFormButton"
            />
            <Button
              variant={ variantTypes.secondary }
              onClick={ this.clear }
              title="Clear query form"
              dataTest="queryFilterFormClearQueryFormButton"
            />
          </div>
          <div className={ styles.secondaryActions }>
            <Button
              variant={ variantTypes.success }
              onClick={ this.handleConvert }
              title="Run query"
              dataTest="queryFilterFormRunQueryButton"
            />
            <Button
              variant={ variantTypes.secondary }
              onClick={ this.onOpenPrepareModal }
              title="Prepare query"
              dataTest="queryFilterFormPrepareQueryButton"
            />
          </div>
        </div>
        { this.renderPrepareModal() }
      </form>
    );
  }
}

export default reduxForm({
  form: 'queryFilterForm',
  enableReinitialize: true,
})(QueryFilterForm);
