import { combineReducers } from 'redux';
import collection from './collection/reducer';
import filters from './filters/reducer';
import item from './item/reducer';

export default combineReducers({
  collection,
  filters,
  item,
});
