import * as actionTypes from '../actionTypes';

const initialState = {
  isRefreshModalVisible: false,
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_DEAL_SLIDES_NEED_TO_REFRESH_MODAL_VISIBLE:
      return {
        ...state,
        isRefreshModalVisible: action.payload,
      };
    default:
      return state;
  }
}
