import actionTypes from './actionTypes';
import { getCollectionReducer } from '@/Framework/State/Redux/collectionFactory';

const newState = {
  sortBy: 'createdAt',
  sortOrder: 'desc',
  relatedItems: {},
  bulkDeleteItems: [],
};

export default getCollectionReducer(actionTypes, newState, {
  [actionTypes.GET_COLLECTION_SUCCESS]: (state, action) => (
    {
      ...state,
      isFetching: false,
      data: action.payload.collection,
      totalCount: action.payload.totalCount,
    }
  ),
  [actionTypes.TOGGLE_BULK_DELETE]: (state, action) => (
    {
      ...state,
      bulkDeleteItems: action.payload,
    }
  ),
  [actionTypes.BULK_DELETE_ACCOUNTS]: (state) => (
    {
      ...state,
      isFetching: true,
    }
  ),
});
