import actionTypes from './actionTypes';
import { getBlacklistReducer } from '@/condor/application/actions/blacklists/helpers';

export default getBlacklistReducer(actionTypes, {
  [actionTypes.GET_COLLECTION_SUCCESS]: (state, action) => (
    {
      ...state,
      isFetching: false,
      data: action.payload.data,
      totalCount: action.payload.totalCount,
    }
  ),
});
