import * as actionTypes from './actionTypes';

export function changeFilter(payload) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.QUERY_FILTER_FORM_CHANGE_FILTER,
      payload,
    });
  };
}

export function resetState() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.QUERY_FILTER_FORM_RESET });
  };
}

export function reloadSalt() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.QUERY_FILTER_FORM_CHANGE_SALT });
  };
}
