import { matchPath } from 'react-router';
import { History } from '@/Framework/DI/Providers/History';
import { CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL } from '@/condor/ui/common/constants';

/**
 * @param {Object} state
 * @return {number}
 */
export const getAccountId = (state) => {
  const history = state.container.get(History);

  return parseInt(matchPath(
    history.location.pathname,
    `${ CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL }/:id`,
  ).params.id);
};

/**
 * @param {Number} id
 * @param {String} name
 * @return {Object}
 */
export const createAccountsOptions = ({ id, name }) => (
  {
    value: id,
    label: name,
  }
);
