import {
  createCompanyOptionWithIssuerTicker,
  createCompanyOptionWithIssuerTickerAndAbbreviation,
} from '@/condor/ui/components/Finsight/DealProfile/helpers/createCompanyOption';
import { mapToDefaultOption } from '@/Framework/dataHelpers/selectOptionMappers';
import { fieldsData } from '@/condor/ui/components/Finsight/Companies/MigrateModal/validate';

/**
 * @param {String} fieldName
 * @return {Function}
 */
export const getCompanyFieldOptionMapperByFieldName = (fieldName) => {
  switch (fieldName) {
    case fieldsData.ISSUER.active.name:
    case 'primaryIssuer':
      return createCompanyOptionWithIssuerTicker;
    case fieldsData.UNDERWRITER.active.name:
    case fieldsData.DEALER.active.name:
      return createCompanyOptionWithIssuerTickerAndAbbreviation;
    default:
      return mapToDefaultOption;
  }
};
