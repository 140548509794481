export const REQUEST_CUSTOM_MODAL = 'REQUEST_CUSTOM_MODAL';
export const REQUEST_CUSTOM_MODAL_SUCCESS = 'REQUEST_CUSTOM_MODAL_SUCCESS';
export const REQUEST_CUSTOM_MODAL_ERROR = 'REQUEST_CUSTOM_MODAL_ERROR';
export const REQUEST_CUSTOM_MODAL_UPDATE_SUCCESS = 'REQUEST_CUSTOM_MODAL_UPDATE_SUCCESS';
export const REQUEST_CUSTOM_MODAL_UPDATE_ERROR = 'REQUEST_CUSTOM_MODAL_UPDATE_ERROR';
export const REQUEST_CUSTOM_MODAL_UPDATE = 'REQUEST_CUSTOM_MODAL_UPDATE';
export const CUSTOM_MODAL_RESET = 'CUSTOM_MODAL_RESET';

export const REQUEST_ROADSHOW_CUSTOM_MODAL = 'REQUEST_ROADSHOW_CUSTOM_MODAL';
export const REQUEST_ROADSHOW_CUSTOM_MODAL_SUCCESS = 'REQUEST_ROADSHOW_CUSTOM_MODAL_SUCCESS';
export const REQUEST_ROADSHOW_CUSTOM_MODAL_ERROR = 'REQUEST_ROADSHOW_CUSTOM_MODAL_ERROR';
export const REQUEST_ROADSHOW_CUSTOM_MODAL_RESET = 'REQUEST_ROADSHOW_CUSTOM_MODAL_RESET';
