import * as actionTypes from './actionTypes';
import { removeForbiddenPositions } from '@/condor/application/actions/banners/helpers';

const initialState = {
  data: [],
  positions: [],
  forbiddenKeys: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SUBMIT_UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        data: state.data.map((banner) => {
          if (banner.bannerId === action.payload.bannerId) {
            return action.payload;
          }
          return banner;
        }),
      };
    case actionTypes.DELETE_BANNER_FROM_STORE:
      return {
        ...state,
        data: state.data.filter((banner) => banner.bannerId !== action.payload.id),
      };
    case actionTypes.GET_BANNERS_LIST_SUCCESS:
      return {
        ...state,
        data: [...action.payload.map((banner) => removeForbiddenPositions(banner, state.positions))],
      };
    case actionTypes.GET_BANNERS_POSITIONS_LIST_SUCCESS:
      return {
        ...state,
        positions: action.payload,
      };
    default:
      return state;
  }
}
