import * as actionsTypes from '../actionTypes';

export interface TableFilterInterface {
  search: string,
  radio: {
    status: string,
    type: string,
  },
  paginationGroup: {
    perPage: number,
    activePage: number,
  },
  sort: {
    sortBy: string,
    sortOrder: string,
  },
}

export const initialState = {
  search: '',
  radio: {
    status: '',
    type: '',
  },
  paginationGroup: {
    perPage: 50,
    activePage: 1,
  },
  sort: {
    sortBy: 'id',
    sortOrder: 'desc',
  },
};

export default function tableFilter(state: TableFilterInterface = initialState, action) {
  switch (action.type) {
    case actionsTypes.DEAL_FILES_TABLE_RESET:
      return initialState;
    case actionsTypes.DEAL_FILES_TABLE_FILTER_SEARCH:
      return {
        ...state,
        search: action.payload.value,
        paginationGroup: {
          ...state.paginationGroup,
          activePage: 1,
        },
      };
    case actionsTypes.DEAL_FILES_TABLE_FILTER_PAGINATION:
      return {
        ...state,
        paginationGroup: {
          ...state.paginationGroup,
          activePage: action.payload.value,
        },
      };
    case actionsTypes.DEAL_FILES_TABLE_FILTER_PER_PAGE:
      return {
        ...state,
        paginationGroup: {
          ...state.paginationGroup,
          perPage: action.payload.value,
          activePage: 1,
        },
      };
    case actionsTypes.DEAL_FILES_TABLE_FILTER_RADIO:
      return {
        ...state,
        radio: {
          ...state.radio,
          [action.payload.group]: action.payload.value,
        },
        paginationGroup: {
          ...state.paginationGroup,
          activePage: 1,
        },
      };
    case actionsTypes.DEAL_FILES_TABLE_SORT:
      return {
        ...state,
        sort: {
          sortBy: action.payload.sortBy,
          sortOrder: action.payload.sortOrder,
        },
        paginationGroup: {
          ...state.paginationGroup,
          activePage: 1,
        },
      };
    default:
      return state;
  }
}
