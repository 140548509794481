export const FILINGS_STATUS = {
  DISABLE: 'disable',
  SENT: 'sent',
  PENDING: 'pending',
};

export enum FillingsMapOptions {
  Mapped = 'mapped',
  Unmapped = 'unmapped',
  All = 'all',
}

export const FILINGS_MAP_FILTER = [
  {
    value: FillingsMapOptions.Mapped,
    label: 'Mapped',
  },
  {
    value: FillingsMapOptions.Unmapped,
    label: 'Unmapped',
  },
  {
    value: FillingsMapOptions.All,
    label: 'All',
  },
];
