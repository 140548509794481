export const logicOperators = [
  {
    label: 'AND',
    value: '$and',
  },
  {
    label: 'OR',
    value: '$or',
  },
  // ,
  // {
  //   label: 'NOT',
  //   value: '$not'
  // },
  // {
  //   label: 'REGEX',
  //   value: '$regex'
  // },
  // {
  //   label: 'IN',
  //   value: '$in'
  // },
  // {
  //   label: 'NOT IN',
  //   value: '$nin'
  // }
];
export const conditionalOperators = [
  {
    label: '=',
    value: '$eq',
  },
  {
    label: '>',
    value: '$gt',
  },
  {
    label: '>=',
    value: '$gte',
  },
  {
    label: '<',
    value: '$lt',
  },
  {
    label: '<=',
    value: '$lte',
  },
  {
    label: '<>',
    value: '$ne',
  },
];
