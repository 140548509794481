import * as actionTypes from '../actionTypes';

const initialState = [
  {
    name: 'Condor',
    url: '/core/',
  },
];

export default function breadcrumb(state = initialState, action) {
  switch (action.type) {
    case actionTypes.BREADCRUMB_ADD:
      if (!state.filter((breadcrumb) => breadcrumb.url === action.payload.url).length) {
        return [
          ...state,
          action.payload,
        ];
      }
      return state;
    case actionTypes.BREADCRUMB_REMOVE:
      return state.filter((breadcrumb) => breadcrumb.url !== action.payload);
    default:
      return state;
  }
}
