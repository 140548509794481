import { FillingsMapOptions } from '@/condor/ui/components/Finsight/Filings/FilingsTables/constants';
import { getCollectionReducer } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';

const initialState = {
  dealMapping: FillingsMapOptions.All,
  isDisableEmailModalVisible: false,
  isSendEmailModalVisible: false,
  isResendEmailModalVisible: false,
  isModalVisible: false,
  isModalFetching: false,
  isStatusUpdated: false,
  selectedFiling: {},
  sortBy: 'acceptanceTimestamp',
  sortOrder: 'desc',
};

export default getCollectionReducer(actionTypes, initialState, {
  [actionTypes.SET_DEAL_MAPPING]: (state, action) => ({
    ...state,
    dealMapping: action.payload,
  }),
  [actionTypes.SET_IS_DISABLE_MODAL_VISIBLE]: (state, action) => ({
    ...state,
    isDisableEmailModalVisible: true,
    isStatusUpdated: false,
    isModalVisible: true,
    selectedFiling: action.payload,
  }),
  [actionTypes.SET_IS_SEND_EMAIL_MODAL_VISIBLE]: (state, action) => ({
    ...state,
    isSendEmailModalVisible: true,
    isStatusUpdated: false,
    isModalVisible: true,
    selectedFiling: action.payload,
  }),
  [actionTypes.SET_IS_RESEND_EMAIL_MODAL_VISIBLE]: (state, action) => ({
    ...state,
    isResendEmailModalVisible: true,
    isStatusUpdated: false,
    isModalVisible: true,
    selectedFiling: action.payload,
  }),
  [actionTypes.HIDE_MODAL]: (state) => ({
    ...state,
    isDisableEmailModalVisible: false,
    isSendEmailModalVisible: false,
    isResendEmailModalVisible: false,
    isModalVisible: false,
    isStatusUpdated: false,
  }),
  [actionTypes.UPDATE_STATUS]: (state) => ({
    ...state,
    isModalFetching: true,
  }),
  [actionTypes.UPDATE_STATUS_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
    isModalFetching: false,
    isStatusUpdated: true,
  }),
  [actionTypes.UPDATE_STATUS_ERROR]: (state) => ({
    ...state,
    isModalFetching: false,
  }),
});
