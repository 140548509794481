import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setBreadcrumb, removeBreadcrumb } from '@/condor/ui/common/Breadcrumb/actions';
import {
  getTableData,
  resetTableData,
  paginateTableData,
  itemsPerPageTableData,
  sortTableData,
  goToDatabase,
} from '@/condor/application/actions/databases/current/actions';
import DatabaseTableItemList from './DatabaseTableItemList';

function mapStateToProps(state) {
  return {
    isFetching: state.technicalSupport.databases.current.isFetching,
    collection: state.technicalSupport.databases.current.collection,
    totalCount: state.technicalSupport.databases.current.totalCount,
    queryFilter: state.technicalSupport.databases.queryFilterForm.filter,
    reloadSalt: state.technicalSupport.databases.queryFilterForm.reloadSalt,
    page: state.technicalSupport.databases.current.page,
    perPage: state.technicalSupport.databases.current.perPage,
    sortBy: state.technicalSupport.databases.current.sortBy,
    sortOrder: state.technicalSupport.databases.current.sortOrder,
    path: state.technicalSupport.databases.structure.path,
    columns: state.technicalSupport.databases.structure.pathData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getList: getTableData,
    resetState: resetTableData,
    paginateTableData,
    itemsPerPageTableData,
    setBreadcrumb,
    removeBreadcrumb,
    sortTableData,
    goToDatabase,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DatabaseTableItemList);
