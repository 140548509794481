import * as actionTypes from './actionTypes';
import { conditionalOperators, logicOperators } from './constants';

const initialState = {
  filter: null,
  reloadSalt: new Date().getMilliseconds(),
  logicOperators,
  conditionalOperators,
};

export default function current(state = initialState, action) {
  switch (action.type) {
    case actionTypes.QUERY_FILTER_FORM_RESET:
      return initialState;

    case actionTypes.QUERY_FILTER_FORM_CHANGE_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case actionTypes.QUERY_FILTER_FORM_CHANGE_SALT:
      return {
        ...state,
        reloadSalt: new Date().getMilliseconds(),
      };
    default:
      return state;
  }
}
