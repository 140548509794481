import { domainRegexp } from '@/ui/shared/validators/validateDomain';
import { emailRegexp } from '@/ui/shared/validators/user/validateEmail';

/**
 * @param {String} domain
 * @return {Boolean|String}
 */
export function validateDomain(domain) {
  if (domain && !domainRegexp.test(domain)) {
    return `${ domain } is invalid`;
  }
  return false;
}

/**
 * @param {String} email
 * @return {Boolean|String}
 */
export function validateEmail(email) {
  if (email && !emailRegexp.test(email)) {
    return `${ email } is invalid`;
  }
  return false;
}

export function existInList(blacklistUsersAndDomains, newBlacklist) {
  let exist = blacklistUsersAndDomains.filter(
    (blacklist) => blacklist.domain === newBlacklist || blacklist.email === newBlacklist,
  );
  if (exist.length) {
    return `${ newBlacklist } is already in blacklist`;
  }
  return false;
}
