import filter from 'lodash/filter';
import map from 'lodash/map';
import Banner from '@/condor/domain/banner/Banner';

/**
 * Get Forbidden Positions
 * @param {Array} positions
 * @return {Array}
 */
export const getForbiddenPositions = (positions) => filter(positions, { bannersCount: 1 });

/**
 * Get keys for Forbidden positions
 * @param {Array} positions
 * @return {Array}
 */
export const getForbiddenKeys = (positions) => map(getForbiddenPositions(positions), 'positionId');

/**
 * remove forbidden positions
 * @param {Object} banner
 * @param {Array} positions
 * @return {Array}
 */
export const removeForbiddenPositions = (banner, positions) => {
  const positionsKeys = getForbiddenKeys(positions);

  if (Banner.isNotLive(banner.status)) {
    const filteredPositions = banner.positionIds.filter((position) => !positionsKeys.includes(position));
    return { ...banner, positionIds: [...filteredPositions] };
  }

  return banner;
};
