import * as actionTypes from './actionTypes';
import { getCompanyFieldOptionMapperByFieldName } from './helpers';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONDOR_FILTERED_COMPANIES_OPTIONS_SUCCESS:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.collection
          .map(getCompanyFieldOptionMapperByFieldName(action.payload.fieldName)),
      };
    case actionTypes.RESET_CONDOR_FILTERED_COMPANIES_OPTIONS:
      return initialState;
    default:
      return state;
  }
};
