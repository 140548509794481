import { getItemActionTypes } from '@/Framework/State/Redux/itemFactory';

export default {
  ...getItemActionTypes(
    'CONDOR_ACCOUNTS',
    { withCreate: true, withUpdate: true, withRemove: true },
  ),
  SET_CONDOR_ACCOUNTS_IS_INLINE_EDITING: 'SET_CONDOR_ACCOUNTS_IS_INLINE_EDITING',
  SET_CONDOR_ACCOUNTS_IS_DELETE_MODAL_VISIBLE: 'SET_CONDOR_ACCOUNTS_IS_DELETE_MODAL_VISIBLE',

  RESTORE_CONDOR_ACCOUNT: 'RESTORE_CONDOR_ACCOUNT',
  RESTORE_CONDOR_ACCOUNT_SUCCESS: 'RESTORE_CONDOR_ACCOUNT_SUCCESS',
  RESTORE_CONDOR_ACCOUNT_ERROR: 'RESTORE_CONDOR_ACCOUNT_ERROR',
};
