import * as actionTypes from '../actionTypes';

const initialState = {
  isFetching: false,
  list: [],
  totalCount: 0,
};

export default function dealFiles(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DEAL_FILES_RESET:
      return initialState;
    case actionTypes.REQUEST_DEAL_FILES:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_DEAL_FILES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case actionTypes.REQUEST_DEAL_FILES_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case actionTypes.REQUEST_DEAL_FILES_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
