import { combineReducers } from 'redux';
import collection from './collection/reducer';
import metadata from './metadata/reducer';

export default combineReducers(
  {
    collection,
    metadata,
  },
);
