import { getItemActionTypes } from '@/Framework/State/Redux/itemFactory';

export default {
  ...getItemActionTypes(
    'CONDOR_FINSIGHT_DEAL_CARD',
    {
      withCreate: true,
      withUpdate: true,
      withRemove: true,
    },
  ),
  GET_CONDOR_DEAL_CARD_RATING_AGENCIES: 'GET_CONDOR_DEAL_CARD_RATING_AGENCIES',
  GET_CONDOR_DEAL_CARD_RATING_AGENCIES_SUCCESS: 'GET_CONDOR_DEAL_CARD_RATING_AGENCIES_SUCCESS',
  GET_CONDOR_DEAL_CARD_RATING_AGENCIES_ERROR: 'GET_CONDOR_DEAL_CARD_RATING_AGENCIES_ERROR',

  GET_CONDOR_DEAL_CARD_INITIAL_DATA: 'GET_CONDOR_DEAL_CARD_INITIAL_DATA',
  GET_CONDOR_DEAL_CARD_INITIAL_DATA_SUCCESS: 'GET_CONDOR_DEAL_CARD_INITIAL_DATA_SUCCESS',
  GET_CONDOR_DEAL_CARD_INITIAL_DATA_ERROR: 'GET_CONDOR_DEAL_CARD_INITIAL_DATA_ERROR',

  GET_CONDOR_DEAL_CARD_OPTIONS_DATA: 'GET_CONDOR_DEAL_CARD_OPTIONS_DATA',
  GET_CONDOR_DEAL_CARD_OPTIONS_DATA_SUCCESS: 'GET_CONDOR_DEAL_CARD_OPTIONS_DATA_SUCCESS',
  GET_CONDOR_DEAL_CARD_OPTIONS_DATA_ERROR: 'GET_CONDOR_DEAL_CARD_OPTIONS_DATA_ERROR',

  INITIALIZE_CONDOR_DEAL_CARD: 'INITIALIZE_CONDOR_DEAL_CARD',
  CLEAR_DEAL_CARD_COMPANY_FIELD_OPTIONS: 'CLEAR_DEAL_CARD_COMPANY_FIELD_OPTIONS',

  FETCH_CONDOR_DEAL_CARD_FIELD_COMPANIES_OPTIONS: 'FETCH_CONDOR_DEAL_CARD_FIELD_COMPANIES_OPTIONS',
  FETCH_CONDOR_DEAL_CARD_FIELD_COMPANIES_OPTIONS_SUCCESS: 'FETCH_CONDOR_DEAL_CARD_FIELD_COMPANIES_OPTIONS_SUCCESS',
  FETCH_CONDOR_DEAL_CARD_FIELD_COMPANIES_OPTIONS_ERROR: 'FETCH_CONDOR_DEAL_CARD_FIELD_COMPANIES_OPTIONS_ERROR',

  CREATE_DEAL_WITH_MAP_TO_FILING: 'CREATE_DEAL_WITH_MAP_TO_FILING',
  CREATE_DEAL_WITH_MAP_TO_FILING_SUCCESS: 'CREATE_DEAL_WITH_MAP_TO_FILING_SUCCESS',
  CREATE_DEAL_WITH_MAP_TO_FILING_ERROR: 'CREATE_DEAL_WITH_MAP_TO_FILING_ERROR',

  UPDATE_DEAL_WITH_MAP_TO_FILING: 'UPDATE_DEAL_WITH_MAP_TO_FILING',
  UPDATE_DEAL_WITH_MAP_TO_FILING_SUCCESS: 'UPDATE_DEAL_WITH_MAP_TO_FILING_SUCCESS',
  UPDATE_DEAL_WITH_MAP_TO_FILING_ERROR: 'UPDATE_DEAL_WITH_MAP_TO_FILING_ERROR',
};
