import * as actionTypes from '../actionTypes';

const initialState = '';

export default function pageTitle(state = initialState, action) {
  switch (action.type) {
    case actionTypes.PAGE_TITLE_SET:
      return action.payload;
    default:
      return state;
  }
}
