import { getCollectionReducer } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';

const newState = {
  sortBy: 'firstName',
  sortOrder: 'asc',
  perPage: 10,
};

export default getCollectionReducer(actionTypes, newState);
