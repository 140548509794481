export const UPLOAD_CLEAR = 'UPLOAD_CLEAR';

// Presentation cue points
export const UPLOAD_FINSIGHT_IMPORT_COMPANY_ADD = 'UPLOAD_FINSIGHT_IMPORT_COMPANY_ADD';
export const UPLOAD_FINSIGHT_IMPORT_COMPANY_CHUNK_SUCCESS = 'UPLOAD_FINSIGHT_IMPORT_COMPANY_CHUNK_SUCCESS';
export const UPLOAD_FINSIGHT_IMPORT_COMPANY_SUCCESS = 'UPLOAD_FINSIGHT_IMPORT_COMPANY_SUCCESS';
export const UPLOAD_FINSIGHT_IMPORT_COMPANY_ERROR = 'UPLOAD_FINSIGHT_IMPORT_COMPANY_ERROR';
export const UPLOAD_FINSIGHT_IMPORT_COMPANY_PROCESSING = 'UPLOAD_FINSIGHT_IMPORT_COMPANY_PROCESSING';
export const UPLOAD_FINSIGHT_IMPORT_COMPANY_DONE = 'UPLOAD_FINSIGHT_IMPORT_COMPANY_DONE';
export const UPLOAD_FINSIGHT_IMPORT_COMPANY_CANCEL = 'UPLOAD_FINSIGHT_IMPORT_COMPANY_CANCEL';
export const UPLOAD_FINSIGHT_IMPORT_COMPANY_CANCEL_SUCCESS = 'UPLOAD_FINSIGHT_IMPORT_COMPANY_CANCEL_SUCCESS';
export const UPLOAD_FINSIGHT_IMPORT_COMPANY_RESET = 'UPLOAD_FINSIGHT_IMPORT_COMPANY_RESET';
