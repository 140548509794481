import { Component } from 'react';
import { Field } from 'redux-form';
import Input from '@/ui/shared/components/Form/Input';
import Select from '@/ui/shared/components/Form/Select/legacy';
import { Clearfix, Icon, IconType } from '@dealroadshow/uikit';

import formsStyles from './Forms/forms.scss';

interface IProps {
  fields: { [key: string]: any },
  label: string,
  logicOperators: { [key: string]: any }[],
  conditionalOperators: { [key: string]: any }[],
}

class Forms extends Component<IProps> {
  constructor(props) {
    super(props);
    this.renderInput = this.renderInput.bind(this);
  }

  renderInput(childName, childIndex) {
    return (
      <div className={ formsStyles.formGroupField }>
        <div className={ formsStyles.formGroupFieldLabel }>{ this.props.label } </div>
        <Field
          name={ `${ childName }.groupSuffix` }
          component={ Input }
          dataTest={ `${ childName }GroupSuffixInput` }
        />
        <Field
          name={ `${ childName }.co` }
          component={ (fieldProps) => (
            <Select
              selectClassName="drop-up"
              label={ fieldProps.label }
              name={ fieldProps.input.name }
              value={ fieldProps.input.value }
              onChange={ fieldProps.input.onChange }
              onBlur={ () => fieldProps.input.onBlur(fieldProps.input.value) }
              options={ this.props.conditionalOperators }
              simpleValue
              dataTest="condorTechnicalSupportQueryFilterSelect"
            />
          ) }
        />
        <Field
          name={ `${ childName }.value` }
          className={ formsStyles.formGroupFieldInput }
          component={ Input }
          dataTest={ `${ childName }ValueInput` }
        />
        <Icon
          type={ IconType.trash }
          className={ formsStyles.formGroupTrashIcon }
          onClick={ () => {
            this.props.fields.remove(childIndex);
          } }
        />
      </div>
    );
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return this.props.fields.map((childName, childIndex) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={ childIndex } className={ formsStyles.formGroupFieldWrapper }>
        { childIndex > 0 && (
          <div className={ formsStyles.formGroupFieldLoWrapper }>
            <Field
              name={ `${ childName }.lo` }
              component={ (fieldProps) => (
                <Select
                  selectClassName="drop-up"
                  label={ fieldProps.label }
                  name={ fieldProps.input.name }
                  value={ fieldProps.input.value }
                  onChange={ fieldProps.input.onChange }
                  onBlur={ () => fieldProps.input.onBlur(fieldProps.input.value) }
                  options={ this.props.logicOperators }
                  simpleValue
                  dataTest="condorTechnicalSupportQueryFilterRenderSelect"
                />
              ) }
            />
          </div>
        ) }
        { this.renderInput(childName, childIndex) }
        <Clearfix />
      </div>
    ));
  }
}

export default Forms;
