import { combineReducers } from 'redux';
import bond from './bond/reducer';
import deal from './deal/reducer';
import company from './company/reducer';

export default combineReducers(
  {
    bond,
    deal,
    company,
  },
);
